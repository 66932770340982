import classNames from "classnames/bind";
import { BaseQueryData, QueryResultProps } from "./type";

import styles from "./style.module.scss";
import { Avatar, Space, Tag } from "antd";
import { useMemo } from "react";
import { TypeGuard } from "@/libs/type-guard";

const cx = classNames.bind(styles);

export const getItemValue = (
  itemValue: any,
  metaInfo: any,
  placeholder = "未知"
) => {
  const isUnknownNumber = typeof itemValue === "number" && itemValue === -1;
  const isUnknownString = typeof itemValue === "string" && itemValue === "";
  const isUnknownDate =
    typeof itemValue === "string" && itemValue === "1970-01-01 00:00:00";

  if (
    isUnknownNumber ||
    isUnknownString ||
    isUnknownDate ||
    TypeGuard.isNullable(itemValue)
  ) {
    return <span style={{ fontSize: 12, color: "#666" }}>{placeholder}</span>;
  }

  if (typeof itemValue === "boolean") {
    return itemValue === true ? metaInfo[0] : metaInfo[1];
  }

  return itemValue;
};

export const QueryResult = function <T extends BaseQueryData>(
  props: QueryResultProps<T>
) {
  const mainContent = useMemo(() => {
    return props.metadata.map((item) => {
      return {
        label: item.title,
        value: Array.isArray(item.key)
          ? item.key.reduce<any[]>((result, keyItem, index) => {
              const value = getItemValue(props.data[keyItem], item.metaInfo);

              if (index !== 0) {
                result.push(<>/</>);
              }

              result.push(value);

              return result;
            }, [])
          : getItemValue(props.data[item.key], item.metaInfo),
      };
    });
  }, [props.data, props.metadata]);

  const primaryValue = props.data[props.primaryKey || "id"];
  const primaryDescription =
    props.data[props.primaryDescriptionKey || "nickname"];

  console.log("props.data", props.data);

  return (
    <div className={cx("QueryResult")}>
      <div className={cx("QueryResult-main")}>
        <div className={cx("QueryResult-avatar")}>
          <Avatar size={48} key={props.avatar} src={props.avatar}>{props.avatar}</Avatar>
        </div>
        <div className={cx("QueryResult-content-container")}>
          <div className={cx("QueryResult-header")}>
            <span
              className={cx("QueryResult-header-value", "clickable-text")}
              onClick={() => props.onClick?.(props.data)}
            >
              {primaryDescription}
            </span>
            {/* <>
              <span className={cx("QueryResult-header-seperator")}>/</span>
              <span className={cx("QueryResult-header-label")}>
                {primaryValue}
              </span>
            </> */}
          </div>
          <div className={cx("QueryResult-content")}>
            {mainContent.map((content) => {
              return (
                <div className={cx("QueryResultMainContent")}>
                  <span className={cx("QueryResultMainContent-label")}>
                    {content.label}
                  </span>
                  <span className={cx("QueryResultMainContent-value")}>
                    {content.value as string}
                  </span>
                </div>
              );
            })}
          </div>

          {props.data.tags ? (
            <div className={cx("QueryResult-extra")}>
              {props.data.tags.map((item) => {
                return (
                  <Tag className={cx("QueryResult-extra-label")}>
                    {item.label}
                  </Tag>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
