import { RouteObject } from "react-router-dom";
import { Home } from "./pages/Home";
import { SimpleHome } from "./pages/Home/SimpleHome";

export const dashboardRoutes: RouteObject[] = [
  {
    path: "",
    // element: <Home />,
    element: <SimpleHome />
  },
];
