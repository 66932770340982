import { RouteManager } from "@/libs";
import {
  ContactsOutlined,
  FileSearchOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

const accountBase = RouteManager.createBase("account");
const appBase = RouteManager.createBase("app");

const ACCOUNT = {
  PROFILE: accountBase("profile", UserOutlined),
  DETAIL: accountBase("detail", ContactsOutlined),
  LIST: accountBase("list", TeamOutlined),
  LOGIN: accountBase("login", TeamOutlined),
  LOGOUT: accountBase("logout", TeamOutlined),
};

const MEWE = {
  SEARCH: appBase("mewe", FileSearchOutlined),
  DETAIL: appBase("mewe/detail", FileSearchOutlined),
};

const TWITTER = {
  SEARCH: appBase("twitter", FileSearchOutlined),
  DETAIL: appBase("twitter/detail"),
};

const TELEGRAM = {
  SEARCH: appBase("telegram", FileSearchOutlined),
  DETAIL: appBase("telegram/detail", FileSearchOutlined),
};

const FACEBOOK = {
  SEARCH: appBase("facebook", FileSearchOutlined),
  DETAIL: appBase("facebook/detail"),
};

export const ROUTE_CONFIG = {
  HOME: RouteManager.createHome(),
  ACCOUNT,
  MEWE,
  TWITTER,
  TELEGRAM,
  FACEBOOK,
};
