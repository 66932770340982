import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import { Description } from "@/components/Description/Description";
import { PageHeader } from "@/components/PageHeader/PageHeader";
import { TableManager } from "@/libs/table/table-manager";
import { Avatar, Spin, Table, Tabs, Tag, Typography, message } from "antd";
import classNames from "classnames/bind";
import { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { ViewTelegramAccountProps } from "./type";
import { useSearchParam } from "react-use";
import { fetchTelegramAccountDetail } from "../../http";
import { getItemValue } from "@/components/QueryResult/QueryResult";
import { TypeGuard } from "@/libs/type-guard";

const cx = classNames.bind(styles);

const tabItems = [
  {
    label: "账户变更记录",
    key: "changelog",
  },
  // {
  //   label: "关联群",
  //   key: "group",
  // },
  // {
  //   label: "关联账号",
  //   key: "related-account",
  // },
];

export const ViewTelegramAccount = (props: ViewTelegramAccountProps) => {
  const [tab, setTab] = useState("changelog");
  const [loading, setLoading] = useState(false);
  const [accountDetail, setAccountDetail] = useState<any>({
    detail: {
      name: "",
      avatarLink: "",
    },
  });
  const searchParam = useRef(
    new URLSearchParams(window.location.search.slice(1))
  );

  useEffect(() => {
    const accountId = searchParam.current.get("accountId") as string;
    setLoading(true);
    fetchTelegramAccountDetail({ accountId }, (data) => {
      const item: any = (data.data as any).detail;

      const nickname = [item.first_name, item.last_name].filter(
        (item) => !TypeGuard.isNullable(item) && item !== ""
      );
      const location = [
        item.phone_country,
        item.phone_province,
        item.phone_city,
      ].filter((item) => !TypeGuard.isNullable(item) && item !== "");
      // return {
      //   ...item,
      //   nickname: nickname.length === 0 ? "" : nickname.join(" "),
      //   location: location.length === 0 ? "" : location.join(" "),
      // };
      setAccountDetail({
        ...(data.data as any),
        detail: {
          ...item,
          nickname: nickname.length === 0 ? "" : nickname.join(" "),
          location: location.length === 0 ? "" : location.join(" "),
        } as any,
      });
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className={cx("ViewTelegramAccount", "app-content-page")}>
      {props.hideHeader ? null : (
        <PageHeader
          title={
            accountDetail.detail.nickname
              ? `${accountDetail.detail.nickname}的详细信息`
              : ""
          }
          onBack={props.onClose}
          backText="返回查询列表"
        ></PageHeader>
      )}
      <Spin spinning={loading}>
        <div className={cx("ViewTelegramAccount-content")}>
          <div className={cx("ViewTelegramAccount-sider")}>
            <AccountProfile
              name={accountDetail.detail.nickname}
              avatarLink={accountDetail.detail.avatar}
            />
            <AccountDescriptions data={accountDetail.detail} />
            <AccountTags tags={accountDetail.tags} />
            <AccountRelatedApp apps={accountDetail.apps} />
          </div>
          <div
            className={cx(
              "ViewTelegramAccount-main-content",
              "block-container"
            )}
          >
            <Tabs
              activeKey={tab}
              className={cx("margin-tab")}
              items={tabItems}
              onChange={setTab}
            ></Tabs>
            {tab === "changelog" ? <AccountChangelog /> : null}
            {/* {tab === "group" ? <AccountRelatedChatGroup /> : null}
          {tab === "related-account" ? (
            <AccountRelatedSocialMediaAccount />
          ) : null} */}
          </div>
        </div>
      </Spin>
    </div>
  );
};

interface AccountProfileProps {
  name: string;
  avatarLink: string;
}

const AccountProfile = (props: AccountProfileProps) => {
  return (
    <div className={cx("AccountProfile", "block-container")}>
      <div className={cx("AccountProfile-avatar")}>
        <Avatar size={64}>{props.avatarLink}</Avatar>
        <div className={cx("AccountProfile-nickName")}>{props.name || "-"}</div>
      </div>
    </div>
  );
};

interface AccountDescriptionsProps {
  data: any;
}

const AccountDescriptions = (props: AccountDescriptionsProps) => {
  const data = props.data || {};

  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="详细信息"
    >
      <Description label="用户ID" value={getItemValue(data["user_id"], "")} />
      <Description label="用户名" value={getItemValue(data["username"], "")} />
      <Description
        label="用户昵称"
        value={getItemValue(data["nickname"], "")}
      />
      <Description
        label="地理位置"
        value={getItemValue(data["location"], "")}
      />
      <Description
        label="是否为机器人"
        value={getItemValue(data["bot"], ["是", "否"])}
      />
      <Description
        label="是否已认证"
        value={getItemValue(data["verified"], ["是", "否"])}
      />
      <Description
        label="是否已删除"
        value={getItemValue(data["deleted"], ["是", "否"])}
      />
      <Description
        label="账号是否涉嫌诈骗"
        value={getItemValue(data["scam"], ["是", "否"])}
      />
      <Description
        label="账号是否受限"
        value={getItemValue(data["restricted"], ["是", "否"])}
      />
      <Description
        label="账户创建时间"
        value={getItemValue(data["created_time"], "")}
      />
      <Description
        label="账户更新时间"
        value={getItemValue(data["updated_time"], "")}
      />
      <Description label="邮箱" value={getItemValue(data["email"], "")} />
      <Description label="手机号" value={getItemValue(data["phone"], "")} />
    </BlockContainer>
  );
};

interface AccountTagsProps {
  tags?: any[];
}

const AccountTags = (props: AccountTagsProps) => {
  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="关联标签"
    >
      {Array.isArray(props.tags) && props.tags.length > 0 ? (
        props.tags.map((item) => {
          return <Tag>{item}</Tag>;
        })
      ) : (
        <div className="empty-block-text">暂无标签信息</div>
      )}
    </BlockContainer>
  );
};

interface AccountRelatedAppProps {
  apps?: Array<{
    type: "facebook" | "linkedin" | "telegram" | "mewe" | "twitter";
    link: string;
  }>;
}

const AccountRelatedApp = (props: AccountRelatedAppProps) => {
  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="关联社交账号"
    >
      {Array.isArray(props.apps) && props.apps.length > 0 ? (
        props.apps.map((app) => <Tag>{app.type}</Tag>)
      ) : (
        <div className="empty-block-text">暂无标签信息</div>
      )}
    </BlockContainer>
  );
};

const CHANGELOG_COLUMNS = [
  TableManager.column("createdTime", "记录时间"),
  TableManager.column("accountId", "用户ID"),
  TableManager.column("accountName", "用户名称"),
  TableManager.column("nickName", "用户昵称"),
];

const AccountChangelog = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered columns={CHANGELOG_COLUMNS}></Table>
    </div>
  );
};

const AccountRelatedChatGroup = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered></Table>
    </div>
  );
};

const AccountRelatedSocialMediaAccount = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered></Table>
    </div>
  );
};
