export class HttpStatus {
  static Continue = 100;
  static Ok = 200;
  static MultipleChoice = 300;
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static InternalServerError = 500;
  static ServiceUnavailable = 503;
  static BadGateway = 504;

  static SuccessBizCode = 0;

  static isStatusBetween(status: number, min: number, max: number) {
    return min <= status && status < max;
  }

  static isInformation(status: number) {
    return HttpStatus.isStatusBetween(
      status,
      HttpStatus.Continue,
      HttpStatus.Ok
    );
  }

  static isSuccess(status: number) {
    return HttpStatus.isStatusBetween(
      status,
      HttpStatus.Ok,
      HttpStatus.MultipleChoice
    );
  }

  static isRedirection(status: number) {
    return HttpStatus.isStatusBetween(
      status,
      HttpStatus.MultipleChoice,
      HttpStatus.BadRequest
    );
  }

  static isClientError(status: number) {
    return HttpStatus.isStatusBetween(
      status,
      HttpStatus.BadRequest,
      HttpStatus.InternalServerError
    );
  }

  static isServerError(status: number) {
    return HttpStatus.isStatusBetween(
      status,
      HttpStatus.InternalServerError,
      600
    );
  }

  static isBizCodePassed(code: unknown) {
    return typeof code === "number" && code === HttpStatus.SuccessBizCode;
  }
}
