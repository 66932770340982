import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import {
  ArrowRightOutlined,
  InfoCircleOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { Statistic, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { getAppStatistics } from "../../http";

import classNames from "classnames/bind";
import styles from "./style.module.scss";
import { ROUTE_CONFIG } from "@/config/route.config";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

const formatter = (value: number | string) => (
  <CountUp end={value as number} duration={1} separator="," />
);

export const SimpleHome = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const handleNaviate = useCallback((item: any) => {
    const routeMatch = (ROUTE_CONFIG as any)[item.appName.toUpperCase() as any];

    if (!routeMatch) {
      return;
    }

    navigate(routeMatch.SEARCH.path);
  }, [navigate]);

  useEffect(() => {
    getAppStatistics((data) => {
      setData(data.data as any);
    });
  }, []);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "48px",
          fontWeight: "bold",
        }}
      >
        欢迎使用境外数据侦查系统
      </div>
      <div className={cx("Home-statistic-blocks")}>
        {data.map((item) => {
          return (
            <div className={cx("Home-statistic-block-item")}>
              <BlockContainer
                className={cx("Home-statistic-block")}
                contentClassName={cx("Home-statistic-content")}
                title={
                  <span>
                    {`${item.appName} 实时数据`}
                    <Tooltip title="更新数量为每天更新的数量，包含对已落库的同账户历史数据的增量更新">
                      <InfoCircleOutlined
                        style={{ paddingLeft: "8px", color: "#999" }}
                      />
                    </Tooltip>
                  </span>
                }
                withExtraAction
                key={item.appName}
                extraActionIcon={<ArrowRightOutlined />}
                extraActionText={`进入 ${item.appName} 查询`}
                extraActionType="link"
                onExtraActionTrigger={() => handleNaviate(item)}
              >
                {item.statistics.map((statistic: any) => {
                  return (
                    <Statistic
                      key={`${item.appName}-${statistic.title}`}
                      formatter={formatter}
                      className={cx("Home-statistic")}
                      title={statistic.title}
                      value={statistic.value}
                    />
                  );
                })}
              </BlockContainer>
            </div>
          );
        })}
      </div>
    </div>
  );
};
