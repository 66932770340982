import { AccountManager } from "@/libs/account";
import { ThymetError } from "@/libs/error";
import { HttpManager, HttpStatus } from "@/libs/service";
import { message } from "antd";
import { errorNotify } from "./error-notify";

export const http = new HttpManager({
  baseURL: process.env.REACT_APP_SERVICE_HOST,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + AccountManager.getToken(),
  },
});

http.registerErrorResolver((error: ThymetError, response: unknown) => {
  const statusCode = (response as any)?.status;

  if (statusCode === HttpStatus.Unauthorized) {
    message.error("用户未登录，即将跳转登录页...");

    window.location.href = "/account/login";
  }

  if (error instanceof ThymetError) {
    return errorNotify(error);
  }

  errorNotify(undefined, "网络请求异常");
});
