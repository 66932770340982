import { TypeGuard } from "../type-guard";
import type { WrapperRunner } from "../types/wrapper";

export class Wrapper<T> {
  constructor(private data: T) {}

  async resolveLogic(runner: WrapperRunner<Exclude<T, void>>) {
    if (TypeGuard.isNullable(this.data)) {
      return;
    }

    return runner(this.data as Exclude<T, void>);
  }
}
