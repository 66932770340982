import { Layout } from "antd";
import {
  CSSProperties,
  useCallback,
  useMemo,
  type PropsWithChildren,
} from "react";
import { TextConfig } from "@/config";
import { Outlet, useMatch, useNavigate, useRoutes } from "react-router-dom";

import type { AppLayoutProps } from "./type";
import { RibbonZone } from "../RibbonZone";
import { MenuZone } from "../MenuZone";

import classNames from "classnames/bind";
import styles from "./style.module.scss";
import { AppName } from "../AppName/AppName";
import { ROUTE_CONFIG } from "@/config/route.config";

const cx = classNames.bind(styles);

export const AppLayout = (props: PropsWithChildren<AppLayoutProps>) => {
  const navigate = useNavigate();
  const appNameStyle = useMemo<CSSProperties>(() => {
    return {
      justifyContent: "left",
      color: "white",
      paddingLeft: 20,
      height: 64,
    };
  }, []);

  const handleClick = useCallback(() => {
    if (window.location.pathname !== ROUTE_CONFIG.HOME.path) {
      return navigate(ROUTE_CONFIG.HOME.path);
    }
  }, [navigate]);

  if (props.frameless === true) {
    return (
      <Layout className={cx("AppLayout", "AppLayout-frameless")}>
        <Outlet />
      </Layout>
    );
  }

  return (
    <Layout className={cx("AppLayout")}>
      <Layout.Sider className={cx("AppLayout-sider")}>
        {/* <AppLogo /> */}
        <AppName logo size={16} style={appNameStyle} onClick={handleClick} />
        <MenuZone />
      </Layout.Sider>
      <Layout>
        <Layout.Header className={cx("AppLayout-header")}>
          <div className={cx("AppLayout-header-content")}></div>
          <RibbonZone />
        </Layout.Header>
        <Layout.Content className={cx('AppLayout-content')}>
          <Layout>
            <div className={cx("AppLayout-content")}>
              <Outlet />
            </div>
          </Layout>
        </Layout.Content>
        <Layout.Footer
          className={cx("AppLayout-footer")}
          dangerouslySetInnerHTML={{ __html: TextConfig.Copyright }}
        ></Layout.Footer>
      </Layout>
    </Layout>
  );
};
