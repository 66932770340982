import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AccountManager } from "./libs/account";
import { http } from "./core/manager";
import { matchPath } from "react-router-dom";
import { message, notification } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

AccountManager.init({
  checkLoginUrl: "/api/v1/account/checkLogin",
  loginUrl: "/api/v1/account/login",
  logoutUrl: "/api/v1/account/logout",
  checkLoginResolver: () => {
    const locationPath = window.location.pathname;

    if (
      locationPath === "/account/login" ||
      locationPath === "/account/logout"
    ) {
      message.info("用户已登录，将直接跳转系统首页");

      window.location.href = "/";

      return false;
    }

    return true;
  },
  checkLoginErrorResolver: (error, response) => {
    const whitelist = ["/account/login", "/account/logout"];
    const pathMatched = whitelist.some((whitePath) =>
      matchPath(whitePath, window.location.pathname)
    );

    if (pathMatched) {
      if (response?.status === 401) {
        notification.warning({
          message: "用户未登录",
          description: "请登录后使用本系统",
        });
      } else if (response?.status === 403) {
        notification.warning({
          message: "用户无权限",
          description: "当前用户无权限访问本系统",
        });
      } else {
        notification.error({
          message: "系统异常",
          description: "系统服务出现异常，请联系管理员",
        });
      }
      return true;
    }

    window.location.href = "/account/login";

    return false;
  },
  logoutErrorResolver: () => {
    alert("退出登录失败");
    return true;
  },
  httpClient: http,
});

(async (root) => {
  const shouldRender = await AccountManager.checkLogin();

  if (!shouldRender) {
    return;
  }

  const time = (window as any).__APP_INIT_TIME__ || Date.now();
  const duration = 1000 - Date.now() + time;

  setTimeout(
    () => {
      root.render(<App />);
      document.getElementById("qingyun-loading-page")?.remove();
    },
    duration > 0 ? duration : 0
  );
})(root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
