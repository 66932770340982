import { RouteObject } from "react-router-dom";
import { FacebookList } from "./pages/FacebookList/FacebookList";
import { ViewFacebookAccount } from "./pages/FacebookList/ViewFacebookAccount";

export const facebookRoutes: RouteObject[] = [
  {
    path: "/app/facebook",
    element: <FacebookList />,
  },
  {
    path: "/app/facebook/detail",
    element: <ViewFacebookAccount hideHeader />,
  },
];
