import { useAccount } from "@/hooks/useAccount";
import { ViewAccount } from "../AccountList/ViewAccount";

import classNames from "classnames/bind";
import styles from "./style.module.scss";

const cx = classNames.bind(styles);

export const AccountProfile = () => {
  const account = useAccount();

  return <div className={cx('AccountProfile', 'app-content-page')}><ViewAccount profile open hideHeader data={account?.accountId} /></div>;
};
