import { RouteObject } from "react-router-dom";
import { TwitterList } from "./pages/TwitterList/TwitterList";
import { ViewTwitterAccount } from "./pages/TwitterList/ViewTwitterAccount";

export const twitterRoutes: RouteObject[] = [
  {
    path: "/app/twitter",
    element: <TwitterList />,
  },
  {
    path: "/app/twitter/detail",
    element: <ViewTwitterAccount hideHeader />,
  },
];
