import { ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { CaptchaProps } from "./type";

import classNames from "classnames/bind";
import styles from "./style.module.scss";

const cx = classNames.bind(styles);

export const Captcha = (props: CaptchaProps) => {
  const propsOnCaptchaChange = props.onCaptchaChange;

  const [svgContent, setSvgContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const reloadCaptcha = useCallback(() => {
    const timestamp = Date.now();
    let captchaImageCode = "";
    let captchaQuestionid = "";
    let failure = false;

    setLoading(true);

    axios
      .get(props.url)
      .then((res) => {
        if (res.status === 200 && res?.data?.data?.captchaContent) {
          captchaImageCode = `data:image/svg+xml;base64,${btoa(
            unescape(encodeURIComponent(res?.data?.data?.captchaContent))
          )}`;
          captchaQuestionid = res.data.data.captchaQuestionCode;
        }

        setFailure(false);
      })
      .catch(() => {
        failure = true;
      })
      .finally(() => {
        const duration = 1000 - Date.now() + timestamp;
        setTimeout(
          () => {
            setLoading(false);
            setFailure(failure);
            if (captchaImageCode) {
              propsOnCaptchaChange(captchaQuestionid);
              setSvgContent(captchaImageCode);
            }
          },
          duration > 0 ? duration : 0
        );
      });
  }, [props.url, propsOnCaptchaChange]);

  useEffect(() => {
    reloadCaptcha();
  }, [reloadCaptcha]);

  return (
    <div className={cx("Captcha")} onClick={reloadCaptcha}>
      {failure ? (
        loading ? (
          <span className={cx("Captcha-failure")}>重新获取中...</span>
        ) : (
          <span className={cx("Captcha-failure")}>获取验证码失败，请重试</span>
        )
      ) : (
        <img className={cx("Captcha-image")} src={svgContent} alt="" />
      )}
      <ReloadOutlined spin={loading} />
    </div>
  );
};
