import { RouteObject } from "react-router-dom";
import { AccountList } from "./pages/AccountList";
import { AccountLogin } from "./pages/AccountLogin";
import { AccountLogout } from "./pages/AccountLogout";
import { AccountProfile } from "./pages/AccountProfile";
import { AccountRegister } from "./pages/AccountRegister";

export const accountRoutes: RouteObject[] = [
  {
    path: "/account",
    children: [
      { path: "list", element: <AccountList /> },
      { path: "profile", element: <AccountProfile /> },
    ],
  },
];

export const accountFramelessRoutes: RouteObject[] = [
  {
    path: "/account",
    children: [
      { path: "login", element: <AccountLogin /> },
      { path: "register", element: <AccountRegister /> },
      { path: "logout", element: <AccountLogout /> },
    ],
  },
];
