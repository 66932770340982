import classNames from "classnames/bind";
import { DescriptionProps } from "./type";

import styles from "./style.module.scss";

const cx = classNames.bind(styles);

export const Description = (props: DescriptionProps) => {
  return (
    <div className={cx("Description")}>
      <div className={cx('Description-label')}>{props.label}</div>
      <div className={cx('Description-value')}>{props.value}</div>
    </div>
  );
};
