import type { ErrorCause, ThymetErrorConfig } from '../types'

export class ThymetError<T = unknown> extends Error {
  message: string
  data: T
  causes: ErrorCause[] = []
  errorName: string = ''
  errorMessage: string = ''
  errorData: unknown

  constructor(message: string, config?: ThymetErrorConfig) {
    super(message)

    this.message = message
    this.data = config?.data
    this.causes = config?.causes || []
    this.errorData = this.safeGetData(config?.error)

    if (config?.error instanceof Error) {
      this.stack = config.error.stack
      this.errorName = config.error.name
      this.errorMessage = config.error.message
    }
  }

  private safeGetData(value: unknown) {
    if (value === null || Array.isArray(value)) {
      return value
    }

    switch (typeof value) {
      case 'number':
      case 'string':
      case 'boolean':
      case 'undefined':
        return value
      case 'symbol':
        return value.toString()
      case 'bigint':
        return `BigInt(${value.toString()})`
      case 'function':
        return `[Function ${value.name}] ${value.toString().slice(0, 100)}`
      case 'object':
        return Object.keys(value).reduce<Record<string, unknown>>((result, next) => {
          result[next] = (value as Record<string, unknown>)[next]

          return result
        }, {})
      default:
        return `[Fallback with type ${typeof value}]`
    }
  }
}

export const ThymetErrorFactory = <T>(defaultMessage: string, errorClass?: typeof ThymetError) => {
  const finalErrorClass = errorClass ?? ThymetError

  return class AbstractError extends finalErrorClass<T> {
    public message: string

    constructor(message?: string, config?: ThymetErrorConfig) {
      const finalMessage = message ?? defaultMessage

      super(finalMessage, config)

      this.message = finalMessage
    }
  }
}
