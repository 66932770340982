import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import { Description } from "@/components/Description/Description";
import { PageHeader } from "@/components/PageHeader/PageHeader";
import { TableManager } from "@/libs/table/table-manager";
import { Avatar, Spin, Table, Tabs, Tag, message } from "antd";
import classNames from "classnames/bind";
import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.module.scss";
import { ViewMeweAccountProps } from "./type";
import { useSearchParam } from "react-use";
import { fetchMeweAccountDetail } from "../../http";

const cx = classNames.bind(styles);

const tabItems = [
  {
    label: "账户变更记录",
    key: "changelog",
  },
  // {
  //   label: "关联群",
  //   key: "group",
  // },
  // {
  //   label: "关联账号",
  //   key: "related-account",
  // },
];

export const ViewMeweAccount = (props: ViewMeweAccountProps) => {
  const [tab, setTab] = useState("changelog");
  const [loading, setLoading] = useState(false);
  const [accountDetail, setAccountDetail] = useState<any>({
    detail: {
      name: "",
      avatarLink: "",
    },
  });
  const searchParam = useRef(
    new URLSearchParams(window.location.search.slice(1))
  );

  useEffect(() => {
    const accountId = searchParam.current.get("accountId") as string;

    setLoading(true);
    fetchMeweAccountDetail({ accountId }, (data) => {
      setAccountDetail(data.data as any);
    });
    setLoading(false);
  }, []);

  const avatarUrl = useMemo(() => {
    if (!accountDetail.detail.mewe_id) {
      return "";
    }

    return (
      process.env.REACT_APP_SERVICE_HOST +
      "/api/v1/common/image?image=" +
      encodeURIComponent(
        `https://img.mewe.com/api/v2/photo/profile/64x64/${accountDetail.detail.mewe_id}`
      )
    );
  }, [accountDetail.detail.mewe_id]);

  return (
    <div className={cx("ViewMeweAccount", "app-content-page")}>
      {props.hideHeader ? null : (
        <PageHeader
          title={`${accountDetail.detail.name}的详细信息`}
          onBack={props.onClose}
          backText="返回查询列表"
        ></PageHeader>
      )}
      <Spin spinning={loading}>
        <div className={cx("ViewMeweAccount-content")}>
          <div className={cx("ViewMeweAccount-sider")}>
            <AccountProfile
              name={accountDetail.detail.name}
              avatarLink={avatarUrl}
            />
            <AccountDescriptions data={accountDetail.detail} />
            <AccountTags tags={accountDetail.tags} />
            <AccountRelatedApp apps={accountDetail.apps} />
          </div>
          <div
            className={cx("ViewMeweAccount-main-content", "block-container")}
          >
            <Tabs
              activeKey={tab}
              className={cx("margin-tab")}
              items={tabItems}
              onChange={setTab}
            ></Tabs>
            {tab === "changelog" ? <AccountChangelog /> : null}
            {/* {tab === "group" ? <AccountRelatedChatGroup /> : null}
          {tab === "related-account" ? (
            <AccountRelatedSocialMediaAccount />
          ) : null} */}
          </div>
        </div>
      </Spin>
    </div>
  );
};

interface AccountProfileProps {
  name: string;
  avatarLink: string;
}

const AccountProfile = (props: AccountProfileProps) => {
  return (
    <div className={cx("AccountProfile", "block-container")}>
      <div className={cx("AccountProfile-avatar")}>
        <Avatar size={64} src={props.avatarLink}>{props.avatarLink}</Avatar>
        <div className={cx("AccountProfile-nickName")}>{props.name || "-"}</div>
      </div>
    </div>
  );
};

interface AccountDescriptionsProps {
  data: any;
}

const AccountDescriptions = (props: AccountDescriptionsProps) => {
  const data = props.data || {};

  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="详细信息"
    >
      <Description label="用户ID" value={data["mewe_id"]} />
      <Description label="用户名" value={data["username"]} />
      <Description label="用户昵称" value={data["name"]} />
      <Description label="国家或地区" value={data["country"] || "-"} />
      <Description label="邮箱" value={data["email"] || "-"} />
      <Description label="电话号码" value={data["phone"] || "-"} />
    </BlockContainer>
  );
};

interface AccountTagsProps {
  tags?: any[];
}

const AccountTags = (props: AccountTagsProps) => {
  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="关联标签"
    >
      {Array.isArray(props.tags) && props.tags.length > 0 ? (
        props.tags.map((item) => {
          return <Tag>{item}</Tag>;
        })
      ) : (
        <div className="empty-block-text">暂无标签信息</div>
      )}
    </BlockContainer>
  );
};

interface AccountRelatedAppProps {
  apps?: Array<{
    type: "facebook" | "linkedin" | "telegram" | "mewe" | "twitter";
    link: string;
  }>;
}

const AccountRelatedApp = (props: AccountRelatedAppProps) => {
  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="关联社交账号"
    >
      {Array.isArray(props.apps) && props.apps.length > 0 ? (
        props.apps.map((app) => <Tag>{app.type}</Tag>)
      ) : (
        <div className="empty-block-text">暂无标签信息</div>
      )}
    </BlockContainer>
  );
};

const CHANGELOG_COLUMNS = [
  TableManager.column("createdTime", "记录时间"),
  TableManager.column("accountId", "用户ID"),
  TableManager.column("accountName", "用户名称"),
  TableManager.column("nickName", "用户昵称"),
];

const AccountChangelog = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered columns={CHANGELOG_COLUMNS}></Table>
    </div>
  );
};

const AccountRelatedChatGroup = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered></Table>
    </div>
  );
};

const AccountRelatedSocialMediaAccount = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered></Table>
    </div>
  );
};
