import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import { MassiveQueryForm } from "@/components/MassiveQueryForm/MassiveQueryForm";
import { QueryValue } from "@/components/MassiveQueryForm/type";
import { QueryManager } from "@/components/QueryResult/QueryManager";
import { QueryResult } from "@/components/QueryResult/QueryResult";
import { useMode } from "@/hooks/useMode";
import { Pagination, Spin } from "antd";
import classNames from "classnames/bind";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { TelegramListProps } from "./type";
import { ViewTelegramAccount } from "./ViewTelegramAccount";
import {
  batchFetchTelegramAccountDetail,
  fetchTelegramAccountList,
} from "../../http";
import { TypeGuard } from "@/libs/type-guard";

const cx = classNames.bind(styles);

const TYPE_OPTIONS = [
  {
    label: "任意",
    value: "any",
    placeholder: "请输入要查询的用户ID、用户名或用户昵称",
  },
  {
    label: "用户ID",
    value: "id",
    placeholder: "请输入要查询的用户ID",
  },
  {
    label: "用户名",
    value: "username",
    placeholder: "请输入要查询的用户名",
  },
  {
    label: "用户昵称",
    value: "nickname",
    placeholder: "请输入要查询的用户昵称",
  },
];

const metadata = [
  QueryManager.createMeta("user_id", "用户ID"),
  QueryManager.createMeta("username", "用户名称"),
  QueryManager.createMeta("nickname", "用户昵称"),
  QueryManager.createMeta("location", "位置"),
  QueryManager.createMeta("verified", "账号是否已认证", ["是", "否"]),
  QueryManager.createMeta("bot", "是否为机器人", ["是", "否"]),
  QueryManager.createMeta("deleted", "账号是否已删除", ["是", "否"]),
  QueryManager.createMeta("scam", "账号是否涉嫌诈骗", ["是", "否"]),
  QueryManager.createMeta("restricted", "账号是否受限", ["是", "否"]),
  // QueryManager.createMeta("restriction_reason", "账号受限原因", ['是', '否']),
  QueryManager.createMeta("created_time", "账户创建时间"),
  QueryManager.createMeta("updated_time", "账户更新时间"),
  QueryManager.createMeta("email", "邮箱"),
  QueryManager.createMeta("phone", "手机号"),
];


const batchMetadata = [
  QueryManager.createMeta("user_id", "用户ID"),
  QueryManager.createMeta("username", "用户名称"),
  QueryManager.createMeta("nickname", "用户昵称"),
  QueryManager.createMeta("phone_location_country", "手机号归属地国家"),
  QueryManager.createMeta("phone_location_province", "手机号归属地省份"),
  QueryManager.createMeta("phone_location_city", "手机号归属地城市"),
  QueryManager.createMeta("verified", "账号是否已认证", ["是", "否"]),
  QueryManager.createMeta("bot", "是否为机器人", ["是", "否"]),
  QueryManager.createMeta("deleted", "账号是否已删除", ["是", "否"]),
  QueryManager.createMeta("scam", "账号是否涉嫌诈骗", ["是", "否"]),
  QueryManager.createMeta("restricted", "账号是否受限", ["是", "否"]),
  QueryManager.createMeta("restriction_reason", "账号受限原因", ['是', '否']),
  QueryManager.createMeta("created_time", "账户创建时间"),
  QueryManager.createMeta("updated_time", "账户更新时间"),
  QueryManager.createMeta("email", "邮箱"),
  QueryManager.createMeta("phone", "手机号"),
];

export const TelegramList = (props: TelegramListProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 10 });
  const [mode, setMode] = useMode<"detail" | "list">(
    "accountId",
    "detail",
    "list"
  );
  const [simpleQueryForm, setSimpelQueryForm] = useState<QueryValue>({
    keyword: "",
    type: "any",
    precise: false,
  });
  const [dataResult, setDataResult] = useState({
    list: [] as any[],
    count: { total: 0, totalText: "0" },
  });
  const handleChange = useCallback((value: QueryValue) => {
    setSimpelQueryForm(value);
  }, []);

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setPagination({
        page,
        size: pageSize,
      });

      setLoading(true);
      fetchTelegramAccountList(
        { ...simpleQueryForm, page, size: pageSize },
        (result) => {
          const data = {
            list: ((result.data as any)?.list || []).map((item: any) => {
              const nickname = [item.first_name, item.last_name].filter(
                (item) => !TypeGuard.isNullable(item) && item !== ""
              );
              const location = [
                item.phone_country,
                item.phone_province,
                item.phone_city,
              ].filter((item) => !TypeGuard.isNullable(item) && item !== "");
              return {
                ...item,
                nickname: nickname.length === 0 ? "" : nickname.join(" "),
                location: location.length === 0 ? "" : location.join(" "),
              };
            }),
            count: (result?.data as any)?.count,
          };
          setDataResult(data as any);
        }
      ).finally(() => {
        setLoading(false);
      });
    },
    [simpleQueryForm]
  );

  const handleClickQueryResultItem = useCallback(
    (item: any) => {
      setMode("detail");
      navigate({
        search: `?accountId=${item.id}`,
      });
    },
    [navigate, setMode]
  );

  const handleSearch = useCallback(() => {
    const resetedPagination = { page: 1, size: pagination.size };
    setPagination(resetedPagination);
    setLoading(true);
    fetchTelegramAccountList(
      { ...simpleQueryForm, ...resetedPagination },
      (result) => {
        const data = {
          list: ((result.data as any)?.list || []).map((item: any) => {
            const nickname = [item.first_name, item.last_name].filter(
              (item) => !TypeGuard.isNullable(item) && item !== ""
            );
            const location = [
              item.phone_country,
              item.phone_province,
              item.phone_city,
            ].filter((item) => !TypeGuard.isNullable(item) && item !== "");
            return {
              ...item,
              nickname: nickname.length === 0 ? "" : nickname.join(" "),
              location: location.length === 0 ? "" : location.join(" "),
            };
          }),
          count: (result?.data as any)?.count,
        };
        setDataResult(data as any);
      }
    ).finally(() => {
      setLoading(false);
    });
  }, [simpleQueryForm, pagination]);

  const handleBackList = useCallback(() => {
    setMode("list");
    navigate({
      search: "",
    });
  }, [setMode, navigate]);

  const handleBatchSearch = useCallback(
    async (keywords: string[], type: string) => {
      let data: any[] = [];

      await batchFetchTelegramAccountDetail(
        {
          keywords,
          type,
        },
        (result) => {
          data = result.data as any[];
        }
      );

      return data;
    },
    []
  );

  return (
    <div className={cx("TelegramList", "app-content-page")}>
      {mode === "list" ? (
        <>
          <BlockContainer title="查询条件">
            <MassiveQueryForm
              value={simpleQueryForm}
              typeOptions={TYPE_OPTIONS}
              onChange={handleChange}
              onSearch={handleSearch}
              appType="Telegram"
              metas={batchMetadata}
              onBatchSearch={handleBatchSearch}
            />
          </BlockContainer>
          <Spin spinning={loading}>
            <BlockContainer
              className={cx("block-margin")}
              title={`Telegram 用户列表（共${dataResult.count.totalText}条）`}
            >
              {dataResult.list.map((item) => {
                return (
                  <QueryResult
                    key={item.id}
                    primaryKey="id"
                    primaryDescriptionKey="nickname"
                    onClick={handleClickQueryResultItem}
                    metadata={metadata}
                    data={item}
                  />
                );
              })}
              {dataResult.list.length === 0 ? (
                <div
                  style={{
                    height: "100px",
                    color: "#999",
                    textAlign: "center",
                    lineHeight: "100px",
                  }}
                >
                  暂无数据，请修改关键字后查询
                </div>
              ) : null}
              <div className={cx("pagination-right-align")}>
                <Pagination
                  hideOnSinglePage
                  current={pagination.page}
                  total={dataResult.count.total}
                  pageSize={pagination.size}
                  onChange={handlePaginationChange}
                />
              </div>
            </BlockContainer>
          </Spin>
        </>
      ) : (
        <ViewTelegramAccount onClose={handleBackList} />
      )}
    </div>
  );
};
