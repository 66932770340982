import { ROUTE_CONFIG } from "@/config/route.config";
import { Button, Result } from "antd";
import classNames from "classnames/bind";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import styles from "./style.module.scss";

const cx = classNames.bind(styles);

export const NotFound = () => {
  const navigate = useNavigate();
  const handleBackHome = useCallback(() => {
    navigate(ROUTE_CONFIG.HOME.path);
  }, [navigate]);

  return (
    <div className={cx("NotFound", 'full-height-content')}>
      <Result
        title="页面不存在"
        subTitle={`未找到与路径 ${window.location.pathname} 匹配的页面，请检查页面访问路径是否正确`}
        status="404"
        extra={<Button onClick={handleBackHome}>回到首页</Button>}
      ></Result>
    </div>
  );
};
