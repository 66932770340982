import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import { SearchForm } from "@/components/SearchForm/SearchForm";
import { useMode } from "@/hooks/useMode";
import { TableManager } from "@/libs/table/table-manager";
import { AccountData } from "@/libs/types";
import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Table } from "antd";
import classNames from "classnames/bind";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountService } from "../../http";
import { EditAccount } from "./EditAccount";
import styles from "./style.module.scss";
import { EditMode } from "./type";
import { ViewAccount } from "./ViewAccount";

const cx = classNames.bind(styles);

const TABLE_DATA_COLUMNS = [
  TableManager.column<AccountData>("accountName", "账户名称", { width: 100 }),
  TableManager.column<AccountData>("nickName", "昵称", { width: 150 }),
  TableManager.column<AccountData>("telephone", "电话号码", { width: 100 }),
  TableManager.renderColumn<AccountData>(
    "areaCode",
    "所在地区",
    (_, record) => {
      if (record.parentAreaCodeText === record.areaCodeText) {
        return <>{record.parentAreaCodeText}</>;
      }
      return (
        <>
          {record.parentAreaCodeText} {record.areaCodeText}
        </>
      );
    },
    {
      width: 100,
    }
  ),
  TableManager.renderColumn<AccountData>(
    "usedQueryTimes",
    "已使用/总查询次数",
    (_, record) => {
      return (
        <>
          {record.usedQueryTimes}/{record.totalQueryTimes}
        </>
      );
    },
    {
      width: 150,
    }
  ),
  // TableManager.column<AccountData>("totalQueryTimes", "总查询次数", {
  //   width: 100,
  // }),
  TableManager.renderColumn<AccountData>(
    "usedMatchTimes",
    "已使用/总落查次数",
    (_, record) => {
      return (
        <>
          {record.usedMatchTimes}/{record.totalMatchTimes}
        </>
      );
    },
    {
      width: 150,
    }
  ),
  // TableManager.column<AccountData>("totalMatchTimes", "总落查次数", {
  //   width: 100,
  // }),
  TableManager.column<AccountData>("batchCount", "单次最大批量查询数", {
    width: 100,
  }),
  TableManager.column<AccountData>("expiredTime", "账户有效期", { width: 100 }),
  TableManager.column<AccountData>("createdTime", "创建时间", { width: 100 }),
];

const MODAL_TITLE: Record<EditMode, string> = {
  create: "创建账户",
  update: "修改",
  readonly: "",
  hidden: "",
};

export const AccountList = () => {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [editMode, setEditMode, accountIdQuery] = useMode<EditMode>(
    "accountId",
    "readonly",
    "hidden"
  );
  const cachedKeywordRef = useRef(keyword);
  const isEditMode = editMode === "create" || editMode === "update";
  const isViewMode = editMode === "readonly";
  const title = MODAL_TITLE[editMode];
  const [accountId, setAccountId] = useState<string | null>(accountIdQuery);
  const navigate = useNavigate();

  const handleEditAccount = useCallback(
    (accountId: string | null, mode: EditMode = "update") => {
      setAccountId(accountId);
      setEditMode(mode);
    },
    [setEditMode]
  );

  const columns = useMemo(() => {
    const accountIdColumn = TableManager.renderColumn<AccountData>(
      "accountId",
      "账户ID",
      (accountId: string, record) => {
        const handleClick = () => {
          setAccountId(accountId);
          setEditMode("readonly");
          navigate({ search: `?accountId=${accountId}` });
        };

        return (
          <span className={cx("clickable-text")} onClick={handleClick}>
            {accountId}
          </span>
        );
      },
      {
        width: 150,
      }
    );
    const operationColumn = TableManager.renderColumn<AccountData>(
      "action",
      "操作",
      (_, record: AccountData) => {
        return (
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditAccount(record.accountId)}
          >
            {MODAL_TITLE.update}
          </Button>
        );
      },
      {
        align: "center",
        width: 80,
      }
    );

    return [accountIdColumn, ...TABLE_DATA_COLUMNS, operationColumn];
  }, [handleEditAccount, navigate, setEditMode]);

  const fetchAccountList = useCallback(() => {
    accountService.searchAccountList<AccountData[]>(
      cachedKeywordRef.current,
      (response) => {
        setData(response.data ?? []);
      }
    );
  }, []);

  const handleKeywordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(e.target.value);
    },
    [setKeyword]
  );

  const handleSearch = useCallback(() => {
    cachedKeywordRef.current = keyword;
    fetchAccountList();
  }, [keyword, fetchAccountList]);

  const handleCloseEditAccount = useCallback(() => {
    setEditMode("hidden");
  }, [setEditMode]);

  const handleConfirm = useCallback(
    async (data: Partial<AccountData>) => {
      const resolveLogic = () => {
        handleCloseEditAccount();
        fetchAccountList();
      };

      if (editMode === "create") {
        accountService.createAccount(data, resolveLogic);
      } else {
        accountService.updateAccount(data, resolveLogic);
      }
    },
    [handleCloseEditAccount, fetchAccountList, editMode]
  );

  const handleCloseViewAccount = useCallback(() => {
    setEditMode("hidden");
    navigate({ search: "" });
  }, [navigate, setEditMode]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx("account-list-page", "app-content-page")}>
      {!isViewMode ? (
        <>
          <BlockContainer title="查询条件" key="search-form">
            <SearchForm onSearch={handleSearch}>
              <SearchForm.Item label="账户关键字">
                <Input
                  allowClear
                  placeholder="请输入账户名、账户ID或昵称"
                  onChange={handleKeywordChange}
                  value={keyword}
                />
              </SearchForm.Item>
            </SearchForm>
          </BlockContainer>
          <BlockContainer
            key="search-result"
            className="block-margin"
            title={`账户列表查询结果（共 ${data.length} 条）`}
            withExtraAction
            extraActionText={MODAL_TITLE.create}
            onExtraActionTrigger={() => handleEditAccount(null, "create")}
          >
            <div style={{ overflow: "auto" }}>
              <Table
                rowKey="accountId"
                bordered
                dataSource={data}
                columns={columns}
              />
            </div>
          </BlockContainer>

          <Drawer
            open={isEditMode}
            maskClosable={false}
            title={title}
            destroyOnClose
            onClose={handleCloseEditAccount}
            width={500}
          >
            <EditAccount
              mode={editMode}
              data={accountId}
              onClose={handleCloseEditAccount}
              onConfirm={handleConfirm}
            />
          </Drawer>
        </>
      ) : (
        <ViewAccount
          open={isViewMode}
          data={accountId}
          onClose={handleCloseViewAccount}
        />
      )}
    </div>
  );
};
