import { MenuConfig } from "@/config";
import { useCallback, useMemo } from "react";

import classNames from "classnames/bind";
import styles from "./style.module.scss";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useAccount } from "@/hooks/useAccount";

const cx = classNames.bind(styles);

const getMenuItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: any[],
  type?: "group"
) => {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};

export const MenuZone = (props: any) => {
  const pathname = useMemo(() => {
    const pathname = window.location.pathname;
    const matchedConfig = MenuConfig.find(
      (menu) => pathname.indexOf(menu.path as any) >= 0
    );

    if (matchedConfig) {
      return {
        defaultOpenKeys: [matchedConfig.path],
        defaultSelectedKeys: [pathname],
      };
    }

    return {
      defaultSelectedKeys: [pathname],
    };
  }, []);

  const navigate = useNavigate();
  const account = useAccount();
  const permissions = account?.accountPermissions || [];
  const menuItems = useMemo(() => {
    return MenuConfig.filter((menu) => {
      return (
        menu.permission === undefined ||
        permissions.indexOf(menu.permission) >= 0 ||
        permissions.indexOf("ALL") >= 0
      );
    }).map((menu) => {
      return getMenuItem(
        menu.title,
        menu.path as any,
        null
        // menu.children
        //   ? menu.children.map((menuItem) => {
        //       return getMenuItem(menuItem.title, menuItem.path);
        //     })
        //   : null
      );
    });
  }, []);

  const handleMenuChange = useCallback(
    (menu: any) => {
      navigate(menu.key);
    },
    [navigate]
  );

  return (
    <div className={cx("MenuZone")}>
      <Menu
        theme="dark"
        mode="inline"
        {...pathname}
        items={menuItems as any}
        onSelect={handleMenuChange}
      ></Menu>
    </div>
  );
};
