import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import { MassiveQueryForm } from "@/components/MassiveQueryForm/MassiveQueryForm";
import { QueryValue } from "@/components/MassiveQueryForm/type";
import { QueryManager } from "@/components/QueryResult/QueryManager";
import { QueryResult } from "@/components/QueryResult/QueryResult";
import { useMode } from "@/hooks/useMode";
import { Pagination, Spin } from "antd";
import classNames from "classnames/bind";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import { TwitterListProps } from "./type";
import { ViewTwitterAccount } from "./ViewTwitterAccount";
import {
  batchFetchTwitterAccountDetail,
  fetchTwitterAccountList,
} from "../../http";

const cx = classNames.bind(styles);

const TYPE_OPTIONS = [
  {
    label: "任意",
    value: "any",
    placeholder: "请输入要查询的用户ID、用户名或用户昵称",
  },
  {
    label: "用户ID",
    value: "id",
    placeholder: "请输入要查询的用户ID",
  },
  {
    label: "用户名",
    value: "username",
    placeholder: "请输入要查询的用户名",
  },
  {
    label: "用户昵称",
    value: "nickname",
    placeholder: "请输入要查询的用户昵称",
  },
];

const metadata = [
  QueryManager.createMeta("twitter_id", "用户ID"),
  QueryManager.createMeta("screen_name", "用户名称"),
  QueryManager.createMeta("nickname", "用户昵称"),
  QueryManager.createMeta("location", "位置"),
  QueryManager.createMeta("followers_count", "粉丝数"),
  QueryManager.createMeta("friends_count", "关注账户数"),
  QueryManager.createMeta("favourites_count", "点赞的推文数"),
  QueryManager.createMeta("statuses_count", "发推文数"),
  QueryManager.createMeta("verified", "是否认证", ["是", "否"]),
  QueryManager.createMeta("created_at", "账户创建时间"),
  QueryManager.createMeta("email", "邮箱"),
  QueryManager.createMeta("phone", "手机号"),
];

export const TwitterList = (props: TwitterListProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 10 });
  const [mode, setMode] = useMode<"detail" | "list">(
    "accountId",
    "detail",
    "list"
  );
  const [simpleQueryForm, setSimpelQueryForm] = useState<QueryValue>({
    keyword: "",
    type: "any",
    precise: false,
  });
  const [dataResult, setDataResult] = useState({
    list: [] as any[],
    count: { total: 0, totalText: "0" },
  });
  const handleChange = useCallback((value: QueryValue) => {
    setSimpelQueryForm(value);
  }, []);

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setPagination({
        page,
        size: pageSize,
      });

      setLoading(true);
      fetchTwitterAccountList(
        { ...simpleQueryForm, page, size: pageSize },
        (result) => {
          setDataResult(result.data as any);
        }
      ).finally(() => {
        setLoading(false);
      });
    },
    [simpleQueryForm]
  );

  const handleClickQueryResultItem = useCallback(
    (item: any) => {
      setMode("detail");
      navigate({
        search: `?accountId=${item.logid}`,
      });
    },
    [navigate, setMode]
  );

  const handleSearch = useCallback(() => {
    const resetedPagination = { page: 1, size: pagination.size };
    setPagination(resetedPagination);
    setLoading(true);
    fetchTwitterAccountList(
      { ...simpleQueryForm, ...resetedPagination },
      (result) => {
        console.log(result);
        setDataResult(result.data as any);
      }
    ).finally(() => {
      setLoading(false);
    });
  }, [simpleQueryForm, pagination]);

  const handleBackList = useCallback(() => {
    setMode("list");
    navigate({
      search: "",
    });
  }, [setMode, navigate]);

  const handleBatchSearch = useCallback(
    async (keywords: string[], type: string) => {
      let data: any[] = [];

      await batchFetchTwitterAccountDetail(
        {
          keywords,
          type,
        },
        (result) => {
          data = result.data as any[];
        }
      );

      return data;
    },
    []
  );

  return (
    <div className={cx("TwitterList", "app-content-page")}>
      {mode === "list" ? (
        <>
          <BlockContainer title="查询条件">
            <MassiveQueryForm
              value={simpleQueryForm}
              typeOptions={TYPE_OPTIONS}
              onChange={handleChange}
              onSearch={handleSearch}
              appType="Twitter"
              metas={metadata}
              onBatchSearch={handleBatchSearch}
            />
          </BlockContainer>
          <Spin spinning={loading}>
            <BlockContainer
              className={cx("block-margin")}
              title={`Twitter 用户列表（共${dataResult.count.totalText}条）`}
            >
              {dataResult.list.map((item) => {
                const avatarUrl =
                  process.env.REACT_APP_SERVICE_HOST +
                  "/api/v1/common/image?image=" +
                  encodeURIComponent(item.profile_image_url);
                return (
                  <QueryResult
                    key={item.logid}
                    primaryKey="logid"
                    primaryDescriptionKey="nickname"
                    avatar={avatarUrl}
                    onClick={handleClickQueryResultItem}
                    metadata={metadata}
                    data={item}
                  />
                );
              })}
              {dataResult.list.length === 0 ? (
                <div
                  style={{
                    height: "100px",
                    color: "#999",
                    textAlign: "center",
                    lineHeight: "100px",
                  }}
                >
                  暂无数据，请修改关键字后查询
                </div>
              ) : null}
              <div className={cx("pagination-right-align")}>
                <Pagination
                  hideOnSinglePage
                  current={pagination.page}
                  total={dataResult.count.total}
                  pageSize={pagination.size}
                  onChange={handlePaginationChange}
                />
              </div>
            </BlockContainer>
          </Spin>
        </>
      ) : (
        <ViewTwitterAccount onClose={handleBackList} />
      )}
    </div>
  );
};
