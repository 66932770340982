import { ROUTE_CONFIG } from "./route.config";
import { MenuFolder, MenuItem } from "./types/menu";
import { RouteItem } from "./types/route";

class MenuManager {
  static createMenu(
    title: string,
    path: string,
    children: MenuItem[]
  ): MenuFolder {
    return {
      title,
      path,
      children,
    };
  }

  static createMenuItem(title: string, routeItem: RouteItem) {
    return {
      title,
      path: routeItem.path,
    };
  }
}

export const MenuConfig = [
  // MenuManager.createMenu("应用查询", "/app", [
  //   MenuManager.createMenuItem("Telegram", ROUTE_CONFIG.TELEGRAM.SEARCH),
  //   MenuManager.createMenuItem("Twitter", ROUTE_CONFIG.TWITTER.SEARCH),
  //   MenuManager.createMenuItem("Facebook", ROUTE_CONFIG.FACEBOOK.SEARCH),
  //   MenuManager.createMenuItem("Mewe", ROUTE_CONFIG.MEWE.SEARCH),
  // ]),

  // MenuManager.createMenuItem("Telegram", ROUTE_CONFIG.TELEGRAM.SEARCH),
  // MenuManager.createMenuItem("Twitter", ROUTE_CONFIG.TWITTER.SEARCH),
  // MenuManager.createMenuItem("Facebook", ROUTE_CONFIG.FACEBOOK.SEARCH),
  // MenuManager.createMenuItem("Mewe", ROUTE_CONFIG.MEWE.SEARCH),
  { title: 'FACEBOOK', path: ROUTE_CONFIG.FACEBOOK.SEARCH.path, permission: 'FACEBOOK' },
  { title: 'MEWE', path: ROUTE_CONFIG.MEWE.SEARCH.path, permission: 'MEWE' },
  { title: 'TELEGRAM', path: ROUTE_CONFIG.TELEGRAM.SEARCH.path, permission: 'TELEGRAM' },
  { title: 'TWITTER', path: ROUTE_CONFIG.TWITTER.SEARCH.path, permission: 'TWITTER' },
  // MenuManager.createMenu("境外舆情分析", "/opinion", [
  //   { title: "账户列表", path: "/opinion/list" },
  //   { title: "个人信息", path: "/opinion/profile" },
  //   { title: "个人详情", path: "/opinion/detail" },
  // ]),
  // MenuManager.createMenu("专项行动", "/subject", [
  //   { title: "账户列表", path: "/subject/list" },
  //   { title: "个人信息", path: "/subject/profile" },
  //   { title: "个人详情", path: "/subject/detail" },
  // ]),
  // MenuManager.createMenu("标签管理", "/tag", [
  //   { title: "账户列表", path: "/tag/list" },
  //   { title: "个人信息", path: "/tag/profile" },
  //   { title: "个人详情", path: "/tag/detail" },
  // ]),
  // MenuManager.createMenu("任务管理", "/task", [
  //   { title: "账户列表", path: "/task/list" },
  //   { title: "个人信息", path: "/task/profile" },
  //   { title: "个人详情", path: "/task/detail" },
  // ]),
  // MenuManager.createMenu("账户管理", "/account", [
  //   { title: "账户列表", path: "/account/list" },
  //   { title: "个人信息", path: "/account/profile" },
  // ]),

  { title: "账户列表", path: "/account/list", permission: 'ACCOUNT_MANAGEMENT' },
  { title: "个人信息", path: "/account/profile" },
];
