import { ROUTE_CONFIG } from "@/config/route.config";
import { errorNotify } from "@/core/error-notify";
import { useLoading } from "@/hooks/useLoading";
import { AccountManager } from "@/libs/account";
import { ThymetError } from "@/libs/error";
import { HttpStatus } from "@/libs/service";
import { Button, Result } from "antd";
import classNames from "classnames/bind";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./style.module.scss";

const cx = classNames.bind(styles);

export const AccountLogout = () => {
  const navigate = useNavigate();
  const [checking, pending, finish] = useLoading();

  const handleLogin = useCallback(() => {
    navigate(ROUTE_CONFIG.ACCOUNT.LOGIN.path, {
      replace: true,
    });
  }, [navigate]);

  // const handleClose = useCallback(() => {
  //   window.close();
  // }, []);

  useEffect(() => {
    pending();
    AccountManager.checkLogin(true)
      .catch((e) => {
        if (e instanceof ThymetError) {
          const response = e?.data?.response;

          if (HttpStatus.Unauthorized === response?.status) {
            return;
          }

          return errorNotify(e);
        }

        return errorNotify(undefined, "登录检查异常");
      })
      .finally(() => {
        finish();
      });
  }, [pending, finish]);

  if (checking !== "finished") {
    return null;
  }

  return (
    <div className={cx("AccountLogout")}>
      <Result
        status="success"
        title="您已成功退出登录，如需重新登录请前往登录页"
        extra={[
          <Button type="primary" key="login" onClick={handleLogin}>
            前往登录页面
          </Button>,
          // <Button key="close" onClick={handleClose}>
          //   关闭当前页面
          // </Button>,
        ]}
      ></Result>
    </div>
  );
};
