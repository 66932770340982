import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  CreateAxiosDefaults,
} from "axios";
import { Wrapper } from "libs/wrapper";
import { ThymetError, ForbiddenError, UnauthorizedError } from "libs/error";
import { HttpStatus } from "./http-status";

import type {
  HttpServiceErrorData,
  HttpServiceErrorResolver,
  HttpServiceResponse,
  RequestConfig,
} from "libs/types";

export class HttpManager {
  client: AxiosInstance;
  private errorResolver!: HttpServiceErrorResolver;

  constructor(config: CreateAxiosDefaults) {
    this.client = axios.create({
      ...config,
      validateStatus: () => true,
    });
  }

  registerErrorResolver<R, D>(errorResolver: HttpServiceErrorResolver<R, D>) {
    this.errorResolver = errorResolver as HttpServiceErrorResolver;
  }

  static errorInterceptor(error: unknown): ThymetError<HttpServiceErrorData> {
    if (error instanceof ThymetError) {
      return error;
    }

    if (error instanceof AxiosError) {
      return new ThymetError(error.message, {
        data: {
          response: error.response,
          request: error.request,
        },
        error,
      });
    }

    if (error instanceof Error) {
      return new ThymetError(error.message, {
        error,
      });
    }

    const errorMessage = (error as any)?.message;

    return new ThymetError("未知异常", {
      data: {
        error,
      },
      causes: errorMessage ? [{ message: errorMessage }] : [],
    });
  }

  static responseInterceptor<T>(
    response: AxiosResponse<HttpServiceResponse<T>, unknown>
  ): HttpServiceResponse<T> {
    const statusCode = response.status;

    if (
      HttpStatus.isSuccess(statusCode) &&
      HttpStatus.isBizCodePassed(response.data?.code)
    ) {
      return response.data;
    }

    const errorMessage = response?.data?.message || response.statusText;
    const errorData = {
      data: {
        response: response,
        request: response?.config,
      },
      causes: response?.data?.causes || [],
    };

    if (HttpStatus.Unauthorized === statusCode) {
      throw new UnauthorizedError(errorMessage, errorData);
    }

    if (HttpStatus.Forbidden === statusCode) {
      throw new ForbiddenError(errorMessage, errorData);
    }

    throw new ThymetError(errorMessage, errorData);
  }

  static async bindInterceptor<R>(
    requestPromise: Promise<AxiosResponse<HttpServiceResponse<R>>>
  ): Promise<HttpServiceResponse<R>> {
    try {
      const data = await requestPromise;

      return HttpManager.responseInterceptor<R>(data);
    } catch (e) {
      throw HttpManager.errorInterceptor(e);
    }
  }

  static wrap<D>(data: D | undefined) {
    return new Wrapper(data);
  }

  static createRequestPath<T extends Record<string, string>>(
    scope: string,
    record: T
  ) {
    return Object.keys(record).reduce<Record<keyof T, string>>(
      (result, key: keyof T) => {
        result[key] = `${scope}${record[key]}`;

        return result;
      },
      {} as Record<keyof T, string>
    );
  }

  getUri(url: string, params: string) {
    return this.client.getUri({
      url,
      params,
    });
  }

  get<R, P = unknown>(
    url: string,
    params?: P,
    config?: RequestConfig
  ): Promise<Wrapper<HttpServiceResponse<R> | void>> {
    const requestConfig = {
      ...config,
      method: "GET",
      url,
      params,
    };

    return this.request<R>(requestConfig);
  }

  post<R, D>(
    url: string,
    data?: D,
    config?: RequestConfig<D>
  ): Promise<Wrapper<HttpServiceResponse<R> | void>> {
    const requestConfig = {
      ...config,
      method: "POST",
      url,
      data,
    };
    return this.request<R>(requestConfig);
  }

  async request<R>(
    requestConfig: RequestConfig
  ): Promise<Wrapper<HttpServiceResponse<R> | void>> {
    let willWrappedData: HttpServiceResponse<R> | undefined;

    try {
      const data = await this.client.request(requestConfig);

      willWrappedData = HttpManager.responseInterceptor<R>(data);
    } catch (e) {
      const error = HttpManager.errorInterceptor(e);

      if (!this.errorResolver || requestConfig.forceThrowError) {
        throw e;
      }

      this.errorResolver(error, error.data?.response);
    }

    return HttpManager.wrap<HttpServiceResponse<R>>(willWrappedData);
  }
}
