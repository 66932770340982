import { AccountManager } from "@/libs/account";
import { AccountData } from "@/libs/types";
import { useEffect, useState } from "react";

export const useAccount = (): AccountData | null => {
  const [data, setData] = useState<AccountData | null>(
    AccountManager.getAccountData()
  );

  useEffect(() => {
    AccountManager.listen("login", (data) => {
      setData(data);
    });
  }, []);

  return data;
};
