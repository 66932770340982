export const PermissionMap: Record<string, string> = {
  ALL: "全部",
  ACCOUNT: "账户",
  MEWE: "Mewe",
  TWITTER: "Twitter",
  TELEGRAM: "Telegram",
  FACEBOOK: "Facebook",
  TAG: "标签",
  TASK: "任务",
};

export const PermissionConfig = Object.keys(PermissionMap).map((key) => ({
  value: key,
  label: PermissionMap[key] + '权限',
  disabled: key === "ALL" || key === 'ACCOUNT',
}));
