import { RouteObject } from "react-router-dom";
import { MeweList } from "./pages/MeweList/MeweList";
import { ViewMeweAccount } from "./pages/MeweList/ViewMeweAccount";

export const meweRoutes: RouteObject[] = [
  {
    path: "/app/mewe",
    element: <MeweList />,
  },
  {
    path: "/app/mewe/detail",
    element: <ViewMeweAccount hideHeader />,
  },
];
