import { TypeGuard } from "@/libs/type-guard";
import { useState } from "react";
import { useSearchParam } from "react-use";

export const useMode = <T>(param: string, statusIfParam: T, statusElse: T) => {
  const paramValue = useSearchParam(param);

  const [mode, setMode] = useState<T>(
    TypeGuard.isEmpty(paramValue) ? statusElse : statusIfParam
  );

  return [mode, setMode, paramValue] as const;
};
