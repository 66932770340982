import { PageHeader } from "@/components/PageHeader/PageHeader";
import { SearchForm } from "@/components/SearchForm/SearchForm";
import { PermissionConfig } from "@/config/permission.config";
import { useLoading } from "@/hooks/useLoading";
import { AccountData } from "@/libs/types";
import {
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import { TableManager } from "@/libs/table/table-manager";
import classNames from "classnames/bind";
import { useCallback, useEffect, useState } from "react";
import { accountService } from "../../http";
import styles from "./style.module.scss";
import { AccountBasicInfoProps, TabKey, ViewAccountProps } from "./type";

const cx = classNames.bind(styles);

const TAB_INFO = [
  {
    label: "账户基本信息",
    key: "basic-info",
  },
  {
    label: "账户登录日志",
    key: "login-log",
    disabled: true,
  },
  {
    label: "数据查询日志",
    key: "query-log",
    disabled: true,
  },
];

export const ViewAccount = (props: ViewAccountProps) => {
  const [accountData, setAccountData] = useState<AccountData>();
  const [loading, pending, finish] = useLoading();
  const [tab, setTab] = useState<TabKey>("basic-info");
  const handleTabChange = useCallback((activeTab: string) => {
    setTab(activeTab as TabKey);
  }, []);

  useEffect(() => {
    if (typeof props.data !== "string") {
      return;
    }

    pending();

    const request = props.profile
      ? accountService.queryAccountProfile
      : accountService.queryAccountDetail;

    request<AccountData>(props.data, (response) => {
      finish();
      setAccountData(response?.data);
    });
  }, [props.data, pending, finish, props.profile]);

  if (loading === "pending") {
    return (
      <div className={cx("full-height-content")}>
        <Spin></Spin>
      </div>
    );
  }

  if (!props.open) {
    return null;
  }

  if (!props.data) {
    return <Empty description="无用户信息"></Empty>;
  }

  if (!accountData && loading === "finished") {
    return (
      <Empty
        description={`未能匹配到账户ID为 ${props.data} 的账户信息`}
      ></Empty>
    );
  }

  return (
    <div className={cx("ViewAccount")}>
      {props.hideHeader ? null : (
        <PageHeader
          title={`${accountData?.nickName}的账户详细信息`}
          onBack={props.onClose}
          backText="返回账户列表"
        ></PageHeader>
      )}
      <div className={cx("profile-card", "block-container")}>
        <Tabs
          className={cx("margin-tab")}
          activeKey={tab}
          items={TAB_INFO}
          onChange={handleTabChange}
        ></Tabs>
        {tab === "basic-info" ? <AccountBasicInfo data={accountData} /> : null}
        {tab === "login-log" ? <AccountLoginLog /> : null}
        {tab === "query-log" ? <AccountQueryLog /> : null}
      </div>
    </div>
  );
};

export const AccountBasicInfo = (props: AccountBasicInfoProps) => {
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(true);
  const [submitting, setSubmitting] = useState(true);

  if (!props.data) {
    return null;
  }

  return (
    <div className={cx("AccountBasicInfo", "simple-margin")}>
      <Form initialValues={props.data} disabled={true} layout="vertical">
        <Form.Item label="账户名称" name="accountName">
          <Input />
        </Form.Item>
        <Form.Item label="账户昵称" name="nickName">
          <Input />
        </Form.Item>
        <Form.Item label="电话号码" name="telephone">
          <Input />
        </Form.Item>
        <Form.Item label="已使用查询次数" name="usedQueryTimes">
          <InputNumber />
        </Form.Item>
        <Form.Item label="总查询次数" name="totalQueryTimes">
          <InputNumber />
        </Form.Item>
        <Form.Item label="已使用落查次数" name="usedMatchTimes">
          <InputNumber />
        </Form.Item>
        <Form.Item label="总落查次数" name="totalMatchTimes">
          <InputNumber />
        </Form.Item>
        <Form.Item label="单次批量查询最大数量" name="batchCount">
          <InputNumber />
        </Form.Item>
        <Form.Item label="账户权限" name="accountPermissions">
          <Select options={PermissionConfig} mode="multiple"></Select>
        </Form.Item>
        {/* <Form.Item>
          {editing ? (
            <Space>
              <Button
                type="primary"
                loading={submitting}
                disabled={submitting}
                onClick={() => alert("123123")}
              >
                确认
              </Button>
              <Button disabled={submitting}>取消</Button>
            </Space>
          ) : (
            <Button type="primary" disabled={false}>
              编辑账户信息
            </Button>
          )}
        </Form.Item> */}
      </Form>
    </div>
  );
};

const LOGIN_LOG_TABLE_COLUMNS = [
  TableManager.column("logId", "日志ID"),
  TableManager.column("createdTime", "日志时间"),
  TableManager.column("ip", "登录IP"),
  TableManager.column("system", "系统"),
  TableManager.column("browser", "浏览器"),
];

export const AccountLoginLog = () => {
  const handleSearch = useCallback(() => {}, []);

  return (
    <div className={cx("AccountLoginLog", "simple-margin")}>
      <SearchForm onSearch={handleSearch}>
        <SearchForm.Item label="日志时间">
          <DatePicker.RangePicker
            allowClear
            format="YYYY-MM-DD"
          ></DatePicker.RangePicker>
        </SearchForm.Item>
      </SearchForm>
      <Table
        className={cx("simple-top-margin")}
        columns={LOGIN_LOG_TABLE_COLUMNS}
      ></Table>
    </div>
  );
};

const QUERY_LOG_TABLE_COLUMNS = [
  TableManager.column("logId", "日志ID"),
  TableManager.column("createdTime", "日志时间"),
  TableManager.column("module", "查询模块"),
  TableManager.column("request", "请求信息"),
  TableManager.column("responseCount", "返回数据条数"),
];

export const AccountQueryLog = () => {
  const handleSearch = useCallback(() => {}, []);

  return (
    <div className={cx("AccountQueryLog", "simple-margin")}>
      <SearchForm onSearch={handleSearch}>
        <SearchForm.Item label="日志时间">
          <DatePicker.RangePicker
            allowClear
            format="YYYY-MM-DD"
          ></DatePicker.RangePicker>
        </SearchForm.Item>
      </SearchForm>
      <Table
        className={cx("simple-top-margin")}
        columns={QUERY_LOG_TABLE_COLUMNS}
      ></Table>
    </div>
  );
};
