import { HttpPath } from "@/core/http-path";
import { http } from "@/core/manager";
import { AccountData, HttpWrapperRunner } from "@/libs/types";

export const accountService = {
  searchAccountList: async <T>(
    keyword: string,
    resolveLogic: HttpWrapperRunner<T>
  ) => {
    return http
      .get<T>(HttpPath.SEARCH_ACCOUNT_LIST, {
        kw: keyword,
      })
      .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
  },

  queryAccountDetail: async <T>(
    accountId: string,
    resolveLogic: HttpWrapperRunner<T>
  ) => {
    return http
      .get<T>(HttpPath.QUERY_ACCOUNT_DETAIL, {
        accountId,
      })
      .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
  },


  queryAccountProfile: async <T>(
    accountId: string,
    resolveLogic: HttpWrapperRunner<T>
  ) => {
    return http
      .get<T>(HttpPath.QUERY_ACCOUNT_PROFILE, {
        accountId,
      })
      .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
  },

  queryAreaCodeOptions<T>(resolveLogic: HttpWrapperRunner<T>) {
    return http
      .get<T>(HttpPath.QUERY_AREA_CODE_OPTIONS)
      .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
  },

  updateAccount: async (
    accountData: Partial<AccountData>,
    resolveLogic: HttpWrapperRunner<void>
  ) => {
    return http
      .post<void, Partial<AccountData>>(HttpPath.UPDATE_ACCOUNT, accountData)
      .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
  },

  createAccount: async (
    accountData: Partial<AccountData>,
    resolveLogic: HttpWrapperRunner<void>
  ) => {
    return http
      .post<void, Partial<AccountData>>(HttpPath.CREATE_ACCOUNT, accountData)
      .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
  },
};
