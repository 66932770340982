import { BlockContainer } from "@/components/BlockContainer/BlockContainer";
import { Description } from "@/components/Description/Description";
import { PageHeader } from "@/components/PageHeader/PageHeader";
import { TableManager } from "@/libs/table/table-manager";
import { Avatar, Spin, Table, Tabs, Tag, Typography, message } from "antd";
import classNames from "classnames/bind";
import { useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.module.scss";
import { ViewTwitterAccountProps } from "./type";
import { useSearchParam } from "react-use";
import { fetchTweets, fetchTwitterAccountDetail } from "../../http";
import { getItemValue } from "@/components/QueryResult/QueryResult";

const cx = classNames.bind(styles);

const tabItems = [
  {
    label: "账户变更记录",
    key: "changelog",
  },
  // {
  //   label: "关联群",
  //   key: "group",
  // },
  // {
  //   label: "关联账号",
  //   key: "related-account",
  // },
];

export const ViewTwitterAccount = (props: ViewTwitterAccountProps) => {
  const [tab, setTab] = useState("changelog");
  const [loading, setLoading] = useState(false);
  const [accountDetail, setAccountDetail] = useState<any>({
    detail: {
      name: "",
      avatarLink: "",
    },
  });
  const searchParam = useRef(
    new URLSearchParams(window.location.search.slice(1))
  );

  const avatarUrl = useMemo(() => {
    if (!accountDetail.detail.profile_image_url) {
      return "";
    }

    return (
      process.env.REACT_APP_SERVICE_HOST +
      "/api/v1/common/image?image=" +
      encodeURIComponent(accountDetail.detail.profile_image_url)
    );
  }, [accountDetail.detail.profile_image_url]);

  useEffect(() => {
    const accountId = searchParam.current.get("accountId") as string;
    setLoading(true);
    fetchTwitterAccountDetail({ accountId }, (data) => {
      setAccountDetail(data.data as any);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className={cx("ViewTwitterAccount", "app-content-page")}>
      {props.hideHeader ? null : (
        <PageHeader
          title={
            accountDetail.detail.nickname
              ? `${accountDetail.detail.nickname}的详细信息`
              : ""
          }
          onBack={props.onClose}
          backText="返回查询列表"
        ></PageHeader>
      )}
      <Spin spinning={loading}>
        <div className={cx("ViewTwitterAccount-content")}>
          <div className={cx("ViewTwitterAccount-sider")}>
            <AccountProfile
              name={accountDetail.detail.nickname}
              avatarLink={avatarUrl}
            />
            <AccountDescriptions data={accountDetail.detail} />
            <AccountTags tags={accountDetail.tags} />
            <AccountRelatedApp apps={accountDetail.apps} />
          </div>
          <div
            className={cx("ViewTwitterAccount-main-content", "block-container")}
          >
            <Tabs
              activeKey={tab}
              className={cx("margin-tab")}
              items={tabItems}
              onChange={setTab}
            ></Tabs>
            {tab === "changelog" ? <AccountChangelog /> : null}
            {/* {tab === "group" ? <AccountRelatedChatGroup /> : null}
          {tab === "related-account" ? (
            <AccountRelatedSocialMediaAccount />
          ) : null} */}
          </div>
        </div>
      </Spin>
    </div>
  );
};

interface AccountProfileProps {
  name: string;
  avatarLink: string;
}

const AccountProfile = (props: AccountProfileProps) => {
  return (
    <div className={cx("AccountProfile", "block-container")}>
      <div className={cx("AccountProfile-avatar")}>
        <Avatar size={64} src={props.avatarLink}>
          {props.avatarLink}
        </Avatar>
        <div className={cx("AccountProfile-nickName")}>{props.name || "-"}</div>
      </div>
    </div>
  );
};

interface AccountDescriptionsProps {
  data: any;
}

const AccountDescriptions = (props: AccountDescriptionsProps) => {
  const data = props.data || {};

  const userLink = data["url"];
  const userLinkContent =
    userLink && typeof userLink !== "function" ? (
      <Typography.Link href={userLink} target="_blank">
        {userLink}
      </Typography.Link>
    ) : (
      getItemValue(data["url"], "")
    );

  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="详细信息"
    >
      <Description
        label="用户ID"
        value={getItemValue(data["twitter_id"], "")}
      />
      <Description
        label="用户名"
        value={getItemValue(data["screen_name"], "")}
      />
      <Description
        label="用户昵称"
        value={getItemValue(data["nickname"], "")}
      />
      <Description
        label="用户签名"
        value={getItemValue(data["description"], "")}
      />
      <Description label="用户链接" value={userLinkContent} />
      <Description
        label="地理位置"
        value={getItemValue(data["location"], "")}
      />
      <Description
        label="粉丝数"
        value={getItemValue(data["followers_count"], "")}
      />
      <Description
        label="关注账户数"
        value={getItemValue(data["friends_count"], "")}
      />
      <Description
        label="点赞的推文数"
        value={getItemValue(data["favourites_count"], "")}
      />
      <Description
        label="发推文数"
        value={getItemValue(data["statuses_count"], "")}
      />
      <Description
        label="是否已认证"
        value={getItemValue(data["verified"], ["是", "否"])}
      />
      <Description
        label="账户创建时间"
        value={getItemValue(data["created_at"], "")}
      />
      <Description label="邮箱" value={getItemValue(data["email"], "")} />
      <Description label="手机号" value={getItemValue(data["phone"], "")} />
    </BlockContainer>
  );
};

interface AccountTagsProps {
  tags?: any[];
}

const AccountTags = (props: AccountTagsProps) => {
  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="关联标签"
    >
      {Array.isArray(props.tags) && props.tags.length > 0 ? (
        props.tags.map((item) => {
          return <Tag>{item}</Tag>;
        })
      ) : (
        <div className="empty-block-text">暂无标签信息</div>
      )}
    </BlockContainer>
  );
};

interface AccountRelatedAppProps {
  apps?: Array<{
    type: "facebook" | "linkedin" | "telegram" | "mewe" | "twitter";
    link: string;
  }>;
}

const AccountRelatedApp = (props: AccountRelatedAppProps) => {
  return (
    <BlockContainer
      size="small"
      className={cx("block-margin")}
      title="关联社交账号"
    >
      {Array.isArray(props.apps) && props.apps.length > 0 ? (
        props.apps.map((app) => <Tag>{app.type}</Tag>)
      ) : (
        <div className="empty-block-text">暂无标签信息</div>
      )}
    </BlockContainer>
  );
};

const CHANGELOG_COLUMNS = [
  TableManager.column("createdTime", "记录时间"),
  TableManager.column("accountId", "用户ID"),
  TableManager.column("accountName", "用户名称"),
  TableManager.column("nickName", "用户昵称"),
];

const AccountChangelog = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered columns={CHANGELOG_COLUMNS}></Table>
    </div>
  );
};

interface AccountTweetsProps {
  type: string;
  key: string;
}

const AccountTweets = (props: AccountTweetsProps) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  setLoading(true);
  useEffect(() => {
    fetchTweets(
      {
        type: props.type,
        key: props.key,
      },
      (response) => {
        setData(response.data as any);
      }
    ).finally(() => {
      setLoading(false);
    });
  }, [props.type, props.key]);


  return <div className="account-tweets">twitter-accounts</div>;
};

const AccountRelatedChatGroup = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered></Table>
    </div>
  );
};

const AccountRelatedSocialMediaAccount = () => {
  return (
    <div className={cx("simple-margin")}>
      <Table bordered></Table>
    </div>
  );
};
