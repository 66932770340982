import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames/bind";
import { PropsWithChildren, useMemo } from "react";
import styles from "./style.module.scss";
import { BlockContainerProps } from "./type";

const cx = classNames.bind(styles);

export const BlockContainer = (
  props: PropsWithChildren<BlockContainerProps>
) => {
  const titleStyle = useMemo(() => {
    if (props.size === "large") {
      return {
        fontSize: "24px",
        padding: "20px 16px",
      };
    }

    if (props.size === "small") {
      return {
        fontSize: "16px",
        padding: "8px 16px",
      };
    }

    return {};
  }, [props.size]);
  return (
    <div className={cx("BlockContainer", props.className)}>
      <div className={cx("BlockContainer-header")} style={titleStyle}>
        <div className={cx("BlockContainer-header-title")}>{props.title}</div>
        {props.withExtraAction ? (
          <Button
            type={props.extraActionType}
            color={props.extraActionColor}
            icon={
              props.extraActionIcon ? props.extraActionIcon : <PlusOutlined />
            }
            onClick={props.onExtraActionTrigger}
          >
            {props.extraActionText}
          </Button>
        ) : null}
      </div>
      <div className={cx("BlockContainer-content", props.contentClassName)}>
        {props.children}
      </div>
    </div>
  );
};
