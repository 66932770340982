import { createBrowserRouter } from "react-router-dom";
import { accountFramelessRoutes, accountRoutes } from "@/features/account";
import { fallbackRoutes } from "@/features/base";
import { AppLayout } from "./components/AppLayout";
import { dashboardRoutes } from "@/features/dashboard";
import { meweRoutes } from "./features/mewe";
import { twitterRoutes } from "./features/twitter";
import { telegramRoutes } from "./features/telegram";
import { facebookRoutes } from "./features/facebook";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      ...accountRoutes,
      ...dashboardRoutes,
      ...meweRoutes,
      ...twitterRoutes,
      ...telegramRoutes,
      ...facebookRoutes,
      ...fallbackRoutes,
    ],
  },
  {
    path: "/",
    element: <AppLayout frameless />,
    children: [...accountFramelessRoutes],
  },
]);
