import { RouterProvider } from "react-router-dom";
import { router } from "./router";

import "./App.scss";
import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";

function App() {
  return (
    <div className="qingyun-app">
      <ConfigProvider locale={zhCN}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </div>
  );
}

export default App;
