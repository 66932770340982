import { PermissionConfig } from "@/config/permission.config";
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { EditAccountProps } from "./type";

import classNames from "classnames/bind";
import styles from "./style.module.scss";
import { accountService } from "../../http";
import { AccountData } from "@/libs/types";
import { useLoading } from "@/hooks/useLoading";
import { Option, OptionWithChildren } from "@/types/shared";
import dayjs from "dayjs";

const cx = classNames.bind(styles);

const MAX_QUERY_TIMES = 10000;
const MIN_QUERY_TIMES = 0;

export const EditAccount = (props: EditAccountProps) => {
  const [form] = Form.useForm();
  const [accountData, setAccountData] = useState<AccountData>();
  const isNotCreateMode = props.mode !== "create";
  const [loading, pending, finish] = useLoading();
  const [submitting, setSubmitting] = useState(false);
  const [parentAreaCode, setParentAreaCode] = useState("");
  const [areaCodeOptions, setAreaCodeOptions] = useState<Option[]>([]);
  const [areaCodeMap, setAreaCodeMap] = useState<Record<string, Option[]>>();
  const propsOnConfirm = props.onConfirm;
  const shouldDisabled = isNotCreateMode || submitting;
  const [defaultValue] = useState({
    totalQueryTimes: 20,
    totalMatchTimes: 20,
    batchCount: 200,
    expiredTime: dayjs().add(1, "day"),
    accountPermissions: ["ACCOUNT"],
  });

  const handleConfirm = useCallback(async () => {
    try {
      const fieldsValue = form.getFieldsValue();

      fieldsValue.expiredTime = (fieldsValue.expiredTime as dayjs.Dayjs).format(
        "YYYY-MM-DD"
      );

      setSubmitting(true);
      await propsOnConfirm?.(fieldsValue);
    } finally {
      setSubmitting(false);
    }
  }, [propsOnConfirm, form]);

  const handleParentAreaCodeChange = useCallback((value: string) => {
    setParentAreaCode(value);
  }, []);

  useEffect(() => {
    accountService.queryAreaCodeOptions<OptionWithChildren[]>((response) => {
      const options = response?.data || [];
      setAreaCodeOptions(
        options.map((option) => ({
          label: option.label,
          value: option.value,
        }))
      );
      setAreaCodeMap(
        options.reduce<Record<string, Option[]>>((result, option) => {
          result[option.value] = option.children;

          return result;
        }, {})
      );
    });
  }, []);

  useEffect(() => {
    if (!props.data || !areaCodeMap) {
      return;
    }

    pending();
    accountService.queryAccountDetail<AccountData>(props.data, (response) => {
      finish();
      const accountData = response?.data;

      accountData.expiredTime = dayjs(accountData.expiredTime);
      setAccountData(accountData);
      let parentAreaCode = "";

      Object.keys(areaCodeMap).forEach((key) => {
        const options = areaCodeMap[key];

        const matchedOption = options.find((option) => {
          return option.value === response?.data.areaCode;
        });

        if (matchedOption) {
          parentAreaCode = key;
        }
      });

      setParentAreaCode(parentAreaCode);
    });
  }, [props.data, pending, finish, areaCodeMap]);

  if (props.mode === "update") {
    if (loading === "finished" && !accountData) {
      return (
        <Empty
          className={cx("no-data-matched")}
          description="未匹配到用户信息，请关闭弹窗或刷新页面后重试"
        ></Empty>
      );
    }

    if (loading !== "finished") {
      return (
        <div className={cx("full-height-content")}>
          <Spin />
        </div>
      );
    }
  }

  return (
    <Form
      form={form}
      initialValues={isNotCreateMode ? accountData : defaultValue}
      layout="vertical"
      disabled={submitting}
    >
      <Form.Item label="账户ID" hidden name="accountId">
        <Input disabled={shouldDisabled} />
      </Form.Item>
      <Form.Item label="账户名称" name="accountName">
        <Input disabled={shouldDisabled} />
      </Form.Item>
      <Form.Item label="账户昵称" name="nickName">
        <Input />
      </Form.Item>
      <Form.Item label="电话号码" name="telephone">
        <Input disabled={shouldDisabled} />
      </Form.Item>
      {isNotCreateMode ? null : (
        <Form.Item label="密码" name="password">
          <Input.Password />
        </Form.Item>
      )}
      <Form.Item label="所在地区">
        <Space>
          <Form.Item noStyle>
            <Select
              disabled={shouldDisabled}
              options={areaCodeOptions}
              className={cx("EditAccount-select-area")}
              value={parentAreaCode}
              onChange={handleParentAreaCodeChange}
            ></Select>
          </Form.Item>
          <Form.Item name="areaCode" noStyle>
            <Select
              disabled={shouldDisabled}
              options={areaCodeMap?.[parentAreaCode]}
              className={cx("EditAccount-select-area")}
            ></Select>
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item label="总查询次数" name="totalQueryTimes">
        <InputNumber min={MIN_QUERY_TIMES} max={MAX_QUERY_TIMES} />
      </Form.Item>
      <Form.Item label="总落查次数" name="totalMatchTimes">
        <InputNumber min={MIN_QUERY_TIMES} max={MAX_QUERY_TIMES} />
      </Form.Item>
      <Form.Item label="单次批量查询最大数量" name="batchCount">
        <InputNumber min={MIN_QUERY_TIMES} max={MAX_QUERY_TIMES} />
      </Form.Item>
      <Form.Item label="账户有效期至" name="expiredTime">
        <DatePicker
          format="YYYY-MM-DD"
          disabledDate={(date) => !isNotCreateMode && date.isBefore(dayjs())}
        />
      </Form.Item>
      <Form.Item label="账户权限" name="accountPermissions">
        <Select options={PermissionConfig} mode="multiple"></Select>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" onClick={handleConfirm} loading={submitting}>
            确认
          </Button>
          <Button onClick={props.onClose}>取消</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
