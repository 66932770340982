import { useCallback, useState } from "react";
import screenfull from 'screenfull';

export const useFullscreen = () => {
  const [fullscreen, setFullscreen] = useState(false);

  const requestFullscreen = useCallback(() => {
    setFullscreen(true);
    screenfull.request();
  }, []);

  const exitFullscreen = useCallback(() => {
    setFullscreen(false);
    screenfull.exit();
  }, []);

  return [fullscreen, requestFullscreen, exitFullscreen] as const;
};
