import { RouteObject } from "react-router-dom";
import { TelegramList } from "./pages/TelegramList/TelegramList";
import { ViewTelegramAccount } from "./pages/TelegramList/ViewTelegramAccount";

export const telegramRoutes: RouteObject[] = [
  {
    path: "/app/telegram",
    element: <TelegramList />,
  },
  {
    path: "/app/telegram/detail",
    element: <ViewTelegramAccount hideHeader />,
  },
];
