import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import classNames from "classnames/bind";
import { PropsWithChildren, useCallback, useState } from "react";
import styles from "./style.module.scss";
import { SearchFormItemProps, SearchFormProps } from "./type";

const cx = classNames.bind(styles);

export const SearchForm = (props: PropsWithChildren<SearchFormProps>) => {
  const [loading, setLoading] = useState(false);
  const propsOnSearch = props.onSearch;

  const handleSearch = useCallback(async () => {
    setLoading(true);
    try {
      await propsOnSearch?.();
    } finally {
      setLoading(false);
    }
  }, [propsOnSearch]);

  return (
    <div className={cx("SearchForm")}>
      {props.children}
      <SearchForm.Item>
        {props.renderAction ? (
          props.renderAction
        ) : (
          <Space>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              disabled={loading}
              onClick={handleSearch}
            >
              查询
            </Button>
            {props.extraAction ? (
              <Button
                icon={props.extraAction ? props.extraAction : <PlusOutlined />}
                disabled={loading}
                onClick={props.onExtraActionTrigger}
              >
                {props.extraActionText}
              </Button>
            ) : null}
          </Space>
        )}
      </SearchForm.Item>
    </div>
  );
};

SearchForm.Item = (props: PropsWithChildren<SearchFormItemProps>) => {
  return (
    <div className={cx("SearchForm-item")}>
      <div className={cx("SearchForm-item-label")}>{props.label}</div>
      <div className={cx("SearchForm-item-content")}>{props.children}</div>
    </div>
  );
};
