import { HttpManager } from "@/libs/service";

export const HttpPath = {
  ...HttpManager.createRequestPath("/api/v1/common", {
    QUERY_AREA_CODE_OPTIONS: "/options/areaCode",
    QUERY_APP_STATISTICS: '/app/statistics',
  }),
  ...HttpManager.createRequestPath("/api/v1/account", {
    LOGIN: "/login",
    CHECK_LOGIN: "/checkLogin",
    LOGOUT: "/logout",
    SEARCH_ACCOUNT_LIST: "/search",
    QUERY_ACCOUNT_DETAIL: "/detail",
    QUERY_ACCOUNT_PROFILE: "/profile",
    CREATE_ACCOUNT: "/create",
    UPDATE_ACCOUNT: "/update",
  }),
  ...HttpManager.createRequestPath("/api/v1/mewe", {
    SEARCH_MEWE_ACCOUNT_LIST: "/account/query",
    QUERY_MEWE_ACCOUNT_DETAIL: "/account/detail",
    BATCH_QUERY_MEWE_ACCOUNT_DETAIL: "/account/batch",
  }),
  ...HttpManager.createRequestPath("/api/v1/twitter", {
    SEARCH_TWITTER_ACCOUNT_LIST: "/account/query",
    QUERY_TWITTER_ACCOUNT_DETAIL: "/account/detail",
    BATCH_QUERY_TWITTER_ACCOUNT_DETAIL: "/account/batch",
    FETCH_TWITTER_TWEETS: "/account/tweets",
  }),
  ...HttpManager.createRequestPath("/api/v1/telegram", {
    SEARCH_TELEGRAM_ACCOUNT_LIST: "/account/query",
    QUERY_TELEGRAM_ACCOUNT_DETAIL: "/account/detail",
    BATCH_QUERY_TELEGRAM_ACCOUNT_DETAIL: "/account/batch",
  }),
  ...HttpManager.createRequestPath("/api/v1/facebook", {
    SEARCH_FACEBOOK_ACCOUNT_LIST: "/account/query",
    QUERY_FACEBOOK_ACCOUNT_DETAIL: "/account/detail",
    BATCH_QUERY_FACEBOOK_ACCOUNT_DETAIL: "/account/batch",
  }),
};
