import { QueryMetadata } from "./type";

export class QueryManager {
  static createMeta<T>(key: keyof T, title: string, metaInfo?: any): QueryMetadata<T> {
    return {
      key,
      title,
      metaInfo
    };
  }
}
