export class RouteManager {
  static createHome() {
    return {
      name: "[Route] home",
      path: "/",
    };
  }
  static createParent(parent: string, icon?: JSX.Element) {
    return {
      name: `[Route ${parent}]`,
      path: `/${parent}`,
      icon,
    };
  }

  static createBase(parent: string) {
    return (name: string, icon?: React.ComponentType<any>) => {
      return {
        path: `/${parent}/${name}`,
        name: `[Route ${parent}/${name}]`,
        icon,
      };
    };
  }
}
