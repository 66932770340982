import { useCallback, useState } from "react";
import { LoadingType } from "./type";

export const useLoading = () => {
  const [state, setState] = useState<LoadingType>("initial");
  const pending = useCallback(() => {
    setState("pending");
  }, []);
  const finish = useCallback(() => {
    setState("finished");
  }, []);
  const error = useCallback(() => {
    setState("error");
  }, []);

  return [state, pending, finish, error] as const;
};
