import { useAccount } from "@/hooks/useAccount";
import { useFullscreen } from "@/hooks/useFullscreen";
import {
  CompressOutlined,
  ExpandOutlined,
  LogoutOutlined,
  NotificationOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, Space } from "antd";
import { useCallback, useMemo } from "react";

import classNames from "classnames/bind";
import styles from "./style.module.scss";
import { DropdownItem, OperationType } from "./type";
import { useNavigate } from "react-router";
import { ROUTE_CONFIG } from "@/config/route.config";
import { AccountManager } from "@/libs/account";

const cx = classNames.bind(styles);

const OPERATIONS: DropdownItem[] = [
  {
    key: "account-profile",
    label: "个人信息",
    icon: UserOutlined,
  },
  {
    key: "account-logout",
    label: "退出登录",
    icon: LogoutOutlined,
  },
];

export const RibbonZone = () => {
  const navigate = useNavigate();
  const account = useAccount();
  const [fullscreen, requestFullscreen, exitFullscreen] = useFullscreen();
  const handleOperation = useCallback(
    async (op: OperationType) => {
      if (op === "account-profile") {
        navigate(ROUTE_CONFIG.ACCOUNT.PROFILE.path);
      }

      if (op === "account-logout") {
        await AccountManager.logout();
        navigate(ROUTE_CONFIG.ACCOUNT.LOGOUT.path);
      }
    },
    [navigate]
  );

  const menuItems = useMemo(() => {
    return {
      items: OPERATIONS.map((op) => {
        const { icon: Icon, ...extraOp } = op;
        return {
          ...extraOp,
          label: (
            <Space onClick={() => handleOperation(op.key)}>
              <Icon />
              <span key={op.key}>{op.label}</span>
            </Space>
          ),
        };
      }),
    };
  }, [handleOperation]);

  const iconSpaceSize = useMemo<[number, number]>(() => [24, 64], []);

  return (
    <div className={cx("RibbonZone")}>
      <Space size={iconSpaceSize}>
        {/* <SearchOutlined />
        <Badge count={10} size="small" dot>
          <NotificationOutlined />
        </Badge> */}
        {fullscreen ? (
          <CompressOutlined onClick={exitFullscreen} />
        ) : (
          <ExpandOutlined onClick={requestFullscreen} />
        )}
        <Dropdown menu={menuItems} placement="bottom">
          <Space>
            <UserOutlined />
            <span>{account?.nickName}</span>
          </Space>
        </Dropdown>
      </Space>
    </div>
  );
};
