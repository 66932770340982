import { HttpManager } from "libs/service";
import { ThymetError } from "../error";
import { TypeGuard } from "../type-guard";
import {
  AccountData,
  AccountListener,
  AccountManagerEventMap,
  HttpServiceErrorResolver,
  InitAccountManagerConfig,
  LoginData,
  LoginRequestData,
} from "../types";

export class AccountManager {
  static CHECK_LOGIN_URL: string;
  static LOGIN_URL: string;
  static LOGOUT_URL: string;
  static TOKEN_KEY = "qingyun_access_token";
  private static token: string | null = null;
  private static accountData: AccountData | null = null;
  private static listeners: Array<
    [keyof AccountManagerEventMap, AccountListener<any>]
  > = [];
  private static httpClient: HttpManager;
  private static checkLoginResolver: InitAccountManagerConfig["checkLoginResolver"];
  private static checkLoginErrorResolver: InitAccountManagerConfig["checkLoginErrorResolver"];
  private static logoutErrorResolver: HttpServiceErrorResolver;

  static init(config: InitAccountManagerConfig) {
    AccountManager.CHECK_LOGIN_URL = config.checkLoginUrl;
    AccountManager.LOGIN_URL = config.loginUrl;
    AccountManager.LOGOUT_URL = config.logoutUrl;
    AccountManager.checkLoginResolver = config.checkLoginResolver;
    AccountManager.checkLoginErrorResolver = config.checkLoginErrorResolver;
    AccountManager.logoutErrorResolver = config.logoutErrorResolver;
    AccountManager.httpClient = config.httpClient;
  }

  static async checkLogin(skipErrorResolver = false): Promise<boolean> {
    try {
      const responseWrapper = await AccountManager.httpClient.get<AccountData>(
        AccountManager.CHECK_LOGIN_URL,
        undefined,
        { forceThrowError: true, skipErrorResolver }
      );

      await responseWrapper.resolveLogic((response) => {
        const accountData = response.data;
        AccountManager.accountData = accountData;

        AccountManager.setToken(accountData.accessToken);
        AccountManager.emit("login", accountData);
      });

      return AccountManager?.checkLoginResolver?.();
    } catch (e) {
      if (skipErrorResolver) {
        throw e;
      }

      const error = e as ThymetError;

      return AccountManager?.checkLoginErrorResolver?.(
        error,
        (error.data as any)?.response
      );
    }
  }

  static async login(payload: LoginRequestData) {
    const responseWrapper = await AccountManager.httpClient.post<
      LoginData,
      LoginRequestData
    >(AccountManager.LOGIN_URL, payload, { forceThrowError: true });

    await responseWrapper.resolveLogic((response) => {
      const responseData = response.data;

      AccountManager.setToken(responseData.accessToken);
    });
  }

  static async logout() {
    try {
      await AccountManager.httpClient.post(AccountManager.LOGOUT_URL);
      AccountManager.clearToken();
    } catch (e) {
      const error = e as ThymetError;

      AccountManager?.logoutErrorResolver?.(
        error,
        (error.data as any)?.response
      );
    }
  }

  private static setToken(value: string) {
    AccountManager.token = value;
    localStorage.setItem(AccountManager.TOKEN_KEY, value);
  }

  static getToken() {
    if (TypeGuard.isEmpty(AccountManager.token)) {
      const token = localStorage.getItem(AccountManager.TOKEN_KEY);

      AccountManager.token = token;
    }

    return AccountManager.token;
  }

  static clearToken() {
    AccountManager.token = null;
    AccountManager.accountData = null;

    localStorage.removeItem(AccountManager.TOKEN_KEY);
  }

  static getAccountData() {
    return AccountManager.accountData;
  }

  static listen<T extends keyof AccountManagerEventMap>(
    eventName: T,
    listener: AccountListener<T>
  ) {
    if (typeof listener !== "function") {
      return;
    }

    AccountManager.listeners.push([eventName, listener]);
  }

  static emit<T extends keyof AccountManagerEventMap>(
    eventName: T,
    data: AccountManagerEventMap[T]
  ) {
    if (!AccountManager.listeners) {
      return;
    }

    AccountManager.listeners.forEach((listener) => {
      if (listener[0] === eventName) {
        listener[1](data);
      }
    });
  }

  static listenStorageChange() {
    window.addEventListener("storage", (e) => {
      if (e.key === AccountManager.TOKEN_KEY) {
        AccountManager.token = e.newValue;
        AccountManager.emit("token-change", undefined);
      }
    });
  }
}
