import { AppName } from "@/components/AppName/AppName";
import { Captcha } from "@/components/Captcha/Captcha";
import { errorNotify } from "@/core/error-notify";
import { AccountManager } from "@/libs/account";
import { ThymetError } from "@/libs/error";
import { LockOutlined, SafetyOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import classNames from "classnames/bind";
import { useCallback, useState } from "react";
import styles from "./style.module.scss";

const cx = classNames.bind(styles);

export const AccountLogin = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  const onCaptchaChange = useCallback(
    (captchaId: string) => {
      form.setFieldValue("captchaQuestionCode", captchaId);
    },
    [form]
  );

  const handleLogin = useCallback(async () => {
    try {
      setSubmitLoading(true);
      await AccountManager.login(form.getFieldsValue());

      window.location.replace("/");
    } catch (e) {
      const error = e as ThymetError;

      errorNotify(error);
    } finally {
      setSubmitLoading(false);
    }
  }, [form]);

  return (
    <div className={cx("account-login-page")}>
      <AppName size={32} welcome logo padding="0 0 30px 0" />
      <div className={cx("account-login-welcome")}>请登录</div>
      <Form
        form={form}
        className={cx("account-login-form")}
        onFinish={handleLogin}
      >
        <Form.Item name="accountName">
          <Input
            size="large"
            placeholder="请输入用户名"
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item name="password">
          <Input.Password
            name="password"
            size="large"
            placeholder="请输入密码"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Form.Item name="captcha">
              <Input
                size="large"
                placeholder="请输入验证码"
                prefix={<SafetyOutlined />}
              />
            </Form.Item>
            <Captcha
              url={`/api/v1/common/captcha/create`}
              onCaptchaChange={onCaptchaChange}
            />
          </Space>
        </Form.Item>
        <FormItem hidden name="captchaQuestionCode">
          <Input />
        </FormItem>
        <Form.Item>
          <Button
            className={cx("login-form-submit")}
            size="large"
            type="primary"
            htmlType="submit"
            loading={submitLoading}
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
