import classNames from "classnames/bind";
import {
  MassiveQueryFormProps,
  QueryValue,
  SimpleQueryFormProps,
} from "./type";

import styles from "./style.module.scss";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from "antd";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const cx = classNames.bind(styles);

export const MassiveQueryForm = (props: MassiveQueryFormProps) => {
  return (
    <div className={cx("MassiveQueryForm")}>
      <SimpleQueryForm {...props} />
    </div>
  );
};

const TYPE_OPTIONS = [
  {
    label: "任意",
    value: "any",
    placeholder: "请输入要查询的用户ID、用户名、用户昵称、邮箱或者手机号",
  },
  {
    label: "用户ID",
    value: "id",
    placeholder: "请输入要查询的用户ID",
  },
  {
    label: "用户名",
    value: "username",
    placeholder: "请输入要查询的用户名",
  },
  {
    label: "用户昵称",
    value: "nickname",
    placeholder: "请输入要查询的用户昵称",
  },
  {
    label: "邮箱",
    value: "email",
    placeholder: "请输入要查询的邮箱",
  },
  {
    label: "手机号",
    value: "phone",
    placeholder:
      "请输入要查询的手机号，精确查询时需要添加加号和区号，如+861234567890",
  },
];

interface DownloadCSVProps {
  appType: string;
  metas: Array<{ key: string; title: string }>;
  dataList: Array<Record<string, any>>;
}

const DownloadCSV = (props: DownloadCSVProps) => {
  const downloadLink = useMemo(() => {
    return `${props.appType}用户查询结果-${Date.now()}.csv`;
  }, [props.appType]);

  const handleDownload = useCallback(() => {
    const result = [];
    result.push(props.metas.map((meta) => meta.title).join(","));
    result.push(
      ...props.dataList.map((data) => {
        return props.metas.map((meta) => data[meta.key]).join(",");
      })
    );
    const a = document.createElement("a");
    const href = URL.createObjectURL(
      new File([result.join("\n")], downloadLink, {})
    );

    a.download = downloadLink;
    a.target = "_blank";
    a.href = href;
    a.click();
  }, [downloadLink, props.dataList, props.metas]);

  if (props.dataList.length <= 0) {
    return <div>暂无可下载内容</div>;
  }

  return (
    <div>
      点击下载文件：
      <Button type="link" onClick={handleDownload}>
        {downloadLink}
      </Button>
    </div>
  );
};

const SimpleQueryForm = (props: SimpleQueryFormProps) => {
  const propsOnChange = props.onChange;
  const [open, setOpen] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [type, setType] = useState("any");
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState<any[]>([]);
  const typeOptions = useMemo(() => {
    if (props.appType === "Telegram") {
      return TYPE_OPTIONS.filter((option) => option.value !== "email");
    }

    if (props.appType === "Facebook") {
      return TYPE_OPTIONS.filter((option) => option.value !== "username");
    }

    return TYPE_OPTIONS;
  }, [props.appType]);
  const placeholder = useMemo(() => {
    const matchedOption = typeOptions.find(
      (option) => option.value === props.value.type
    );

    return matchedOption?.placeholder ?? "请输入要查询的内容";
  }, [typeOptions, props.value.type]);
  const handleInputTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      propsOnChange?.({
        keyword: e.target.value,
        type: props.value.type,
        precise: props.value.precise,
      });
    },
    [propsOnChange, props.value.type, props.value.precise]
  );

  const handleInputTypeChange = useCallback(
    (value: QueryValue["type"]) => {
      propsOnChange?.({
        keyword: props.value.keyword,
        type: value,
        precise: props.value.precise,
      });
    },
    [propsOnChange, props.value.keyword, props.value.precise]
  );

  const handleInputModeChange = useCallback(
    (e: RadioChangeEvent) => {
      propsOnChange?.({
        keyword: props.value.keyword,
        type: props.value.type,
        precise: e.target.value,
      });
    },
    [propsOnChange, props.value.keyword, props.value.type]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const propsOnBatchSearch = props.onBatchSearch;

  const keywordList = useMemo(() => {
    return keywords
      .split("\n")
      .map((keyword) => {
        return keyword.trim();
      })
      .filter((keyword) => {
        return keyword !== "";
      });
  }, [keywords]);

  const handleBatchQuery = useCallback(async () => {
    setLoading(true);

    try {
      const data = (await propsOnBatchSearch?.(keywordList, type)) || [];
      setDataList(data);
    } finally {
      setLoading(false);
    }
  }, [keywordList, type, propsOnBatchSearch]);

  return (
    <div className={cx("SimpleQueryForm")}>
      <div className={cx("SimpleQueryForm-main-query")}>
        <Input
          className={cx("SimpleQueryForm-input")}
          size="large"
          placeholder={placeholder}
          addonBefore={
            <Select
              value={props.value.type}
              className={cx("SimpleQueryForm-type")}
              options={typeOptions}
              size="large"
              onChange={handleInputTypeChange}
            />
          }
          value={props.value.keyword}
          onChange={handleInputTextChange}
        />
        <Button
          size="large"
          type="primary"
          icon={<SearchOutlined />}
          onClick={props.onSearch}
        >
          查询
        </Button>
        <Button
          size="large"
          className={cx("SimpleQueryForm-massive-query-action")}
          onClick={handleOpen}
        >
          批量查询
        </Button>
        {/* <Button
          size="large"
          disabled
          className={cx("SimpleQueryForm-massive-query-action")}
        >
          使用高级查询
        </Button> */}
      </div>
      <div className={cx("SimpleQueryForm-mode")}>
        <Radio.Group
          value={props.value.precise}
          onChange={handleInputModeChange}
        >
          <Radio value={false}>模糊匹配</Radio>
          <Radio value={true}>精准匹配</Radio>
        </Radio.Group>
      </div>
      <Modal
        title="批量查询用户"
        footer={null}
        open={open}
        destroyOnClose
        onCancel={handleClose}
      >
        <Form style={{ marginTop: 40 }}>
          <Form.Item label="匹配字段">
            <Select
              options={TYPE_OPTIONS}
              value={type}
              onChange={setType}
            ></Select>
          </Form.Item>
          <Form.Item
            label="匹配内容"
            help={`共有 ${keywordList.length} 条数据需要匹配`}
          >
            <TextArea
              autoSize={{
                minRows: 5,
                maxRows: 20,
              }}
              placeholder={`请输入要查询的内容，每条记录一行，比如：\n张三\n李四\n王五`}
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
            ></TextArea>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                loading={loading}
                onClick={handleBatchQuery}
              >
                执行查询
              </Button>
            </Space>
          </Form.Item>
          <Form.Item>
            <DownloadCSV
              metas={props.metas}
              dataList={dataList}
              appType={props.appType}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
