import { ColumnFilterItem, ColumnType } from "antd/es/table/interface";
import { ColumnRender, OnFilter } from "../types/table";

export class TableManager {
  static column<T = object, R = object>(
    dataIndex: string,
    title: string,
    extraConfig?: ColumnType<T>,
    filters?: ColumnFilterItem[],
    onFilter?: OnFilter<R>
  ) {
    return {
      dataIndex,
      key: dataIndex,
      title,
      filters,
      onFilter,
      ...extraConfig
    };
  }

  static renderColumn<T = object, R = object>(
    dataIndex: string,
    title: string,
    render: ColumnRender<T>,
    extraConfig?: ColumnType<T>,
    filters?: ColumnFilterItem[],
    onFilter?: OnFilter<R>
  ) {
    return {
      dataIndex,
      key: dataIndex,
      title,
      filters,
      onFilter,
      render,
      ...extraConfig,
    };
  }

  static filter(text: string, value: string, children?: ColumnFilterItem[]) {
    const sharedConfig: ColumnFilterItem = {
      text,
      value,
    };

    if (children) {
      sharedConfig.children = children;
    }

    return sharedConfig;
  }
}
