import { ThymetError } from "@/libs/error";
import { notification } from "antd";
import { ReactNode } from "react";

export const errorNotify = (
  e: ThymetError | undefined,
  message?: string,
  description?: ReactNode
) => {
  const errorDescription = (e?.causes || []).map((cause) => {
    return <span className="error-notification-message">{cause.message}</span>;
  });

  return notification.error({
    message: message ?? e?.message,
    description: description ?? errorDescription,
  });
};
