import classNames from "classnames/bind";
import { PageHeaderProps } from "./type";

import styles from "./style.module.scss";
import { Button, Space } from "antd";
import { LeftOutlined } from "@ant-design/icons";

const cx = classNames.bind(styles);

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <div className={cx("PageHeader")}>
      <Space>
        <Button icon={<LeftOutlined />} onClick={props.onBack}>
          {props.backText ? props.backText : '返回上一页'}
        </Button>
        <span className={cx('PageHeader-title')}>{props.title}</span>
      </Space>
    </div>
  );
};
