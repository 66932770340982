import { HttpPath } from "@/core/http-path";
import { http } from "@/core/manager";
import { HttpWrapperRunner } from "@/libs/types";

export interface FetchListQuery {
  keyword: string;
  precise: boolean;
  type: "any" | "username" | "nickname" | "id";
  page: number;
  size: number;
}

export interface FetchDetailQuery {
  accountId: string;
}

export interface BatchFetchDetailQuery {
  keywords: string[];
  type: string;
}

export interface FetchTweetsQuery {
  type: string;
  key: string;
}

export const fetchTwitterAccountList = <T>(
  payload: FetchListQuery,
  resolveLogic: HttpWrapperRunner<T>
) => {
  return http
    .get<T, FetchListQuery>(HttpPath.SEARCH_TWITTER_ACCOUNT_LIST, payload)
    .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
};

export const fetchTwitterAccountDetail = <T>(
  payload: FetchDetailQuery,
  resolveLogic: HttpWrapperRunner<T>
) => {
  return http
    .get<T>(HttpPath.QUERY_TWITTER_ACCOUNT_DETAIL, payload)
    .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
};

export const batchFetchTwitterAccountDetail = <T>(
  payload: BatchFetchDetailQuery,
  resolveLogic: HttpWrapperRunner<T>
) => {
  return http
    .post<T, any>(HttpPath.BATCH_QUERY_TWITTER_ACCOUNT_DETAIL, payload)
    .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
};

export const fetchTweets = <T>(
  payload: FetchTweetsQuery,
  resolveLogic: HttpWrapperRunner<T>
) => {
  return http
    .get<T>(HttpPath.FETCH_TWITTER_TWEETS, payload)
    .then((responseWrapper) => responseWrapper.resolveLogic(resolveLogic));
};
