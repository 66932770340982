import classNames from "classnames/bind";
import { useMemo } from "react";
import styles from "./style.module.scss";
import { AppNameProps } from "./type";
import Favicon from "@/assets/icons/logo.svg";

const cx = classNames.bind(styles);

export const AppName = (props: AppNameProps) => {
  const appName = useMemo(() => {
    const name = "境外数据侦查系统";

    if (props.welcome) {
      return `欢迎访问${name}`;
    }

    return name;
  }, [props.welcome]);

  const style = useMemo(() => {
    return {
      fontSize: props.size ?? 20,
      padding: props.padding ?? "0px",
      ...props.style,
    };
  }, [props.size, props.padding, props.style]);

  const imageStyle = useMemo(() => {
    const size = props.size ?? 20;

    return {
      width: size * 1.3,
      height: size * 1.3,
    };
  }, [props.size]);

  return (
    <div
      style={style}
      className={cx("AppName", { "AppName-cursor": !!props.onClick })}
      onClick={props.onClick}
    >
      {props.logo ? (
        <img
          style={imageStyle}
          className={cx("AppName-logo")}
          src={Favicon}
          alt=""
        />
      ) : null}
      <span>{appName}</span>
    </div>
  );
};
